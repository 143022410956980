import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { SnackbarApiService } from '../helpers/snackbar-api.service';
import { environment } from '@environment';
import { Observable, tap } from 'rxjs';
import { GgHttpUtils } from '@core/utils/http.utils';

@Injectable()
export class ApiUrlnterceptor implements HttpInterceptor {
  constructor(private snackbarApi: SnackbarApiService) {}

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    let headers: HttpHeaders = request.headers;

    if (headers.has(GgHttpUtils.skipSuccessMessageHeader)) {
      headers = headers.delete(GgHttpUtils.skipSuccessMessageHeader);
    }

    return next
      .handle(request.clone({ url: `${environment.apiUrl}/${request.url}`, headers }))
      .pipe(tap((event: HttpEvent<T>) => this.handleSuccessEvent(request, event)));
  }

  private handleSuccessEvent<T>(request: HttpRequest<unknown>, event: HttpEvent<T>): void {
    if (event instanceof HttpResponse && event.ok) {
      if (request.headers.has(GgHttpUtils.skipSuccessMessageHeader)) return;

      if (request.method === 'PATCH' || request.method === 'POST' || request.method === 'DELETE') {
        this.snackbarApi.success();
      }
    }
  }
}
