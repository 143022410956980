import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SIGN_UP_ROUTE_CONFIG } from '@core/constants/routes.constants';
import { User, UserType } from '@core/models/user.models';
import { UserFacade } from '@store/user/user.facade';
import { Observable, filter, map, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SubscribedUserGuard  {
  constructor(private router: Router, private userFacade: UserFacade) {}

  canActivate(): Observable<boolean> {
    return this.isAccessAllowed();
  }

  canLoad(): Observable<boolean> {
    return this.isAccessAllowed();
  }

  private isAccessAllowed(): Observable<boolean> {
    return this.userFacade.user$
      .pipe(filter(Boolean))
      .pipe(
        map((user: User) => {
          if (!user.subscription && user.type !== UserType.Admin && !user.is_verified) {
            this.router.navigate([SIGN_UP_ROUTE_CONFIG.root, SIGN_UP_ROUTE_CONFIG.children.billing]);
            return false;
          }

          return true;
        })
      )

      .pipe(take(1));
  }
}
