<svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  width="20"
  height="20"
  viewBox="0,0,256,256"
>
  <g
    fill="#ffca28"
    fill-rule="nonzero"
    stroke="#ffca28"
    stroke-width="6"
    stroke-linecap="butt"
    stroke-linejoin="round"
    stroke-miterlimit="10"
    stroke-dasharray=""
    stroke-dashoffset="0"
    font-family="none"
    font-weight="none"
    font-size="none"
    text-anchor="none"
    style="mix-blend-mode: normal"
  >
    <path
      transform="scale(5.33333,5.33333)"
      d="M24,4.051l6.49,13.135l14.51,2.104l-10.5,10.222l2.477,14.437l-12.977,-6.812l-12.977,6.812l2.477,-14.437l-10.5,-10.222l14.51,-2.104z"
      id="strokeMainSVG"
    ></path>
  </g>

  <g
    fill="white"
    class="star-content"
    fill-rule="nonzero"
    stroke="none"
    stroke-width="1"
    stroke-linecap="butt"
    stroke-linejoin="miter"
    stroke-miterlimit="10"
    stroke-dasharray=""
    stroke-dashoffset="0"
    font-family="none"
    font-weight="none"
    font-size="none"
    text-anchor="none"
    style="mix-blend-mode: normal"
  >
    <g transform="scale(5.33333,5.33333)">
      <path
        d="M24,4.051l6.49,13.135l14.51,2.104l-10.5,10.222l2.477,14.437l-12.977,-6.812l-12.977,6.812l2.477,-14.437l-10.5,-10.222l14.51,-2.104z"
      ></path>
    </g>
  </g>
</svg>
