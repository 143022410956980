import { Injectable } from '@angular/core';
import {
  Article,
  ArticleCategory,
  LoadArticlesPayload,
  LoadArticlesResponse,
  ShareArticlePayload,
  ShareArticleResponse,
} from '@core/models/article.models';
import { Observable } from 'rxjs';
import { WlcmPaginatedData } from '@wlcm/common';
import { WlcmQueryParams } from '@wlcm/common';
import { HttpApiService } from './http-api.service';

const FEATURE: string = 'article';

@Injectable({ providedIn: 'root' })
export class ArticleApiService {
  constructor(private readonly httpApi: HttpApiService) {}

  get(params: LoadArticlesPayload): Observable<LoadArticlesResponse> {
    return this.httpApi.get<LoadArticlesResponse>(FEATURE, { params });
  }

  getOne(id: string): Observable<Article> {
    return this.httpApi.get<Article>(`${FEATURE}/${id}`);
  }

  getCategories(params: WlcmQueryParams): Observable<WlcmPaginatedData<ArticleCategory>> {
    return this.httpApi.get<WlcmPaginatedData<ArticleCategory>>(`${FEATURE}/tag`, { params });
  }

  share(payload: ShareArticlePayload): Observable<ShareArticleResponse> {
    return this.httpApi.post<ShareArticleResponse>(FEATURE, payload);
  }
}
