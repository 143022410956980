import * as moment from 'moment';
import { Observable, map, audit, interval, of, tap } from 'rxjs';

export class GgStoreUtils {
  static makeConfigurable<T>(): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>): Observable<T> => {
      return source.pipe(map((value: T) => JSON.parse(JSON.stringify(value))));
    };
  }

  static loadingAudit(time: number = 800): (source: Observable<boolean>) => Observable<boolean> {
    let startAt: number;

    return (source: Observable<boolean>): Observable<boolean> => {
      return source.pipe(tap((isLoading: boolean) => isLoading && (startAt = moment().valueOf()))).pipe(
        audit((isLoading: boolean) => {
          if (isLoading) return of(null);

          let difference: number = time - (moment().valueOf() - startAt);

          return interval(difference >= 0 ? difference : 0);
        })
      );
    };
  }
}
