import { Injectable } from '@angular/core';
import { Client, ClientPayload } from '@core/models/client.models';
import { Observable } from 'rxjs';
import { HttpApiService } from './http-api.service';

import { FEATURE as USER_FEATURE } from './user-api.service';

const FEATURE: string = `${USER_FEATURE}/client`;

@Injectable({ providedIn: 'root' })
export class ClientApiService {
  constructor(private readonly httpApi: HttpApiService) {}

  get(): Observable<Client[]> {
    return this.httpApi.get<Client[]>(`${USER_FEATURE}/clients`);
  }

  getByOwner(id: string): Observable<Client[]> {
    return this.httpApi.get<Client[]>(`${FEATURE}/${id}`);
  }

  create(client: ClientPayload): Observable<Client> {
    return this.httpApi.post<Client>(FEATURE, client);
  }

  patch(client: Partial<ClientPayload>): Observable<Client> {
    return this.httpApi.patch<Client>(FEATURE, client);
  }

  delete(id: string): Observable<boolean> {
    return this.httpApi.delete<boolean>(`${FEATURE}/${id}`);
  }

  validate(payload: ClientPayload): Observable<boolean> {
    return this.httpApi.post<boolean>(`${FEATURE}/validate`, payload);
  }
}
