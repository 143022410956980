import { ApiError } from '@core/models/api-error.modes';
import { User } from '@core/models/user.models';
import { createAction, props } from '@ngrx/store';

export const setUser = createAction('[User API] Set User', props<{ payload: User }>());

export const loadUser = createAction('[User API] Load User');
export const loadUserSuccess = createAction('[User API] Load User Success', props<{ response: User }>());
export const loadUserFailure = createAction('[User API] Load User Failure', props<{ error: ApiError }>());

export const patchUser = createAction('[User API] Update User', props<{ payload: Partial<User> }>());
export const patchUserSuccess = createAction('[User API] Update User Success', props<{ response: User }>());
export const patchUserFailure = createAction('[User API] Update User Failure', props<{ error: ApiError }>());

export const reset = createAction('[User API] Reset User');
