<div class="popup-footer" *ngIf="!customContent; else customContentTpl">
  <gg-divider *ngIf="config.hasBottomDivider"></gg-divider>

  <div class="popup-footer__actions">
    <gg-button color="primary" *ngIf="config.secondaryButtonText" (click)="secondaryButtonClick.emit($event)">
      {{ config.secondaryButtonText }}
    </gg-button>

    <gg-button
      color="primary"
      styleType="raised-button"
      *ngIf="config.primaryButtonText"
      (clickEvent)="primaryButtonClick.emit($event)"
      [disabled]="disabled"
      [loading]="isLoading"
    >
      {{ config.primaryButtonText }}
    </gg-button>
  </div>
</div>

<ng-template #customContentTpl>
  <ng-content select="[ggCustomPopupContent]"></ng-content>
</ng-template>
