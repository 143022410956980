<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/menu/Instructors</title>
  <g id="icons/menu/Instructors" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <circle id="Oval" stroke="#FFFFFF" stroke-width="2" cx="7" cy="5" r="4"></circle>
    <path
      d="M7,9 C10.3137085,9 13,11.6862915 13,15 L13,17 L13,17 L1,17 L1,15 C1,11.6862915 3.6862915,9 7,9 Z"
      id="Rectangle"
      stroke="#FFFFFF"
      stroke-width="2"
    ></path>
    <rect id="Rectangle" fill="#FFFFFF" x="15" y="14" width="3" height="2" rx="1"></rect>
    <rect id="Rectangle" fill="#FFFFFF" x="15" y="10" width="3" height="2" rx="1"></rect>
    <rect id="Rectangle" fill="#FFFFFF" x="15" y="6" width="3" height="2" rx="1"></rect>
    <rect id="Rectangle" fill="#FFFFFF" x="15" y="2" width="3" height="2" rx="1"></rect>
  </g>
</svg>
