import { DEFAULT_ARTICLES_SEARCH_PARAMS } from '@core/constants/article.constants';
import { Article } from '@core/models/article.models';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ActionReducer, Action, createReducer, on } from '@ngrx/store';
import { ArticlesState } from './articles.state';

import * as fromActions from './articles.actions';

export const ARTICLES_FEATURE_KEY = 'articles';

const adapter: EntityAdapter<Article> = createEntityAdapter<Article>();

export const initialState: ArticlesState = adapter.getInitialState({
  selected: [],
  isLoaded: false,
  isLoading: false,
  searchParams: { ...DEFAULT_ARTICLES_SEARCH_PARAMS },
  paginatedData: null,
});

export const articlesReducer: ActionReducer<ArticlesState, Action> = createReducer(
  initialState,

  on(fromActions.loadArticles, (state, action) => {
    return { ...state, isLoaded: false, isLoading: true, searchParams: { ...state.searchParams, ...action.payload } };
  }),

  on(fromActions.loadArticlesSuccess, (state, action) => {
    return adapter.setAll(action.response.data, {
      ...state,
      paginatedData: action.response,
      isLoaded: true,
      isLoading: false,
    });
  }),

  on(fromActions.loadArticlesFailure, (state) => ({ ...state, isLoaded: false, isLoading: true })),

  on(fromActions.selectOne, (state, action) => ({ ...state, selected: [...state.selected, action.payload] })),

  on(fromActions.selectOnlyOne, (state, action) => ({ ...state, selected: [action.payload] })),

  on(fromActions.deselectAll, (state) => ({ ...state, selected: [] })),

  on(fromActions.resetArticles, () => initialState)
);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectArticlesIds = selectIds;
export const selectArticlesEntities = selectEntities;
export const selectAllArticles = selectAll;
export const selectArticlesTotal = selectTotal;
