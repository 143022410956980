import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ContentChild } from '@angular/core';
import { DEFAULT_POPUP_CONTENT_CONFIG } from '@core/constants/dialog.constants';
import { GgPopupContentConfig } from '@core/models/popup.models';
import { CustomPopupContentDirective } from '@shared/directives/popups/custom-popup-content.directive';

@Component({
  selector: 'gg-popup-header',
  templateUrl: './popup-header.component.html',
  styleUrls: ['./popup-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupHeaderComponent {
  @Input() config: GgPopupContentConfig = DEFAULT_POPUP_CONTENT_CONFIG;

  @Output() close: EventEmitter<void> = new EventEmitter();

  @ContentChild(CustomPopupContentDirective) customContent: CustomPopupContentDirective;
}
