<svg
  xmlns="http://www.w3.org/2000/svg"
  version="1.0"
  width="18"
  height="18px"
  viewBox="0 0 512.000000 512.000000"
  preserveAspectRatio="xMidYMid meet"
>
  <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
    <path
      d="M1890 4939 c-25 -5 -77 -24 -116 -44 -63 -31 -158 -114 -785 -690 -393 -361 -742 -686 -777 -722 -80 -84 -132 -172 -174 -293 l-33 -95 -3 -1240 c-2 -852 1 -1255 8 -1291 29 -139 134 -279 252 -336 125 -61 27 -58 1733 -58 1719 0 1614 -4 1737 62 125 66 223 205 248 348 8 46 10 423 8 1280 -4 1115 -5 1220 -21 1279 -23 84 -84 213 -133 279 -34 46 -1199 1130 -1469 1367 -58 51 -125 100 -160 116 -97 46 -206 59 -315 38z m180 -811 c54 -37 75 -73 75 -128 0 -84 -63 -145 -148 -145 -89 0 -147 61 -147 153 0 107 133 179 220 120z m-725 -952 c163 -43 290 -170 346 -346 29 -90 29 -240 0 -330 -42 -134 -136 -252 -245 -307 -183 -93 -366 -62 -517 87 -210 208 -210 561 0 771 119 119 270 164 416 125z m1089 -89 c52 -30 78 -79 73 -137 -5 -55 -737 -1803 -769 -1837 -45 -48 -139 -55 -197 -14 -35 25 -66 99 -58 139 11 54 748 1796 770 1820 49 54 119 65 181 29z m429 -1102 c32 -8 77 -25 100 -36 56 -29 166 -142 200 -206 36 -68 67 -191 67 -268 0 -77 -31 -200 -67 -268 -38 -72 -146 -177 -215 -211 -171 -83 -367 -47 -508 94 -101 101 -150 227 -150 385 0 159 49 284 150 385 120 119 272 164 423 125z"
    />
    <path
      d="M1181 2873 c-70 -35 -120 -146 -107 -241 28 -204 227 -259 312 -86 56 115 23 264 -70 319 -50 29 -87 31 -135 8z"
    />
    <path
      d="M2695 1682 c-74 -48 -105 -109 -105 -209 0 -82 22 -135 74 -180 42 -37 74 -48 117 -39 45 9 85 41 116 94 24 41 28 58 28 128 0 72 -3 86 -30 129 -39 62 -72 86 -126 92 -32 3 -52 -1 -74 -15z"
    />
    <path
      d="M2850 4747 c747 -685 1146 -1056 1182 -1098 113 -135 179 -267 230 -459 22 -85 22 -89 28 -1131 l5 -1046 260 111 c343 148 350 151 420 221 100 101 152 238 142 375 -6 64 -63 239 -407 1240 -260 756 -412 1184 -433 1218 -51 84 -124 168 -191 218 -127 96 -158 106 -720 240 -285 68 -522 124 -525 124 -3 0 1 -6 9 -13z"
    />
  </g>
</svg>
