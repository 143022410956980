import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BaseEffect } from '@store/base/base.models';
import { Action, Store } from '@ngrx/store';

import * as fromActions from './area.actions';

import { ClassAreaApiService } from '@core/services/api/class-area-api.service';
import { Observable, switchMap } from 'rxjs';
import { AreaState } from './area.state';

@Injectable()
export class AreaEffects extends BaseEffect<AreaState> {
  constructor(
    private readonly actions$: Actions,
    private readonly classAreaApi: ClassAreaApiService,
    public readonly store: Store<AreaState>
  ) {
    super(store);
  }

  loadAreas$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAreas),
      switchMap(() =>
        this.classAreaApi.get().pipe(this.handleResponse(fromActions.loadAreasSuccess, fromActions.loadAreasFailure))
      )
    )
  );
}
