import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ARTICLES_FEATURE_KEY, selectAllArticles, selectArticlesEntities } from './articles.reducer';
import { ArticlesState } from './articles.state';

const selectArticlesState = createFeatureSelector<ArticlesState>(ARTICLES_FEATURE_KEY);

const getSelected = (state: ArticlesState) => state.selected;
const getSearchParams = (state: ArticlesState) => state.searchParams;
const getPaginatedData = (state: ArticlesState) => state.paginatedData;

export const selectAll = createSelector(selectArticlesState, selectAllArticles);
export const selectEntities = createSelector(selectArticlesState, selectArticlesEntities);
export const selectSearchParams = createSelector(selectArticlesState, getSearchParams);
export const selectPaginatedData = createSelector(selectArticlesState, getPaginatedData);
export const selectSelected = createSelector(selectArticlesState, getSelected);

export const selectIsLoaded = createSelector(selectArticlesState, (state: ArticlesState) => state.isLoaded);
export const selectIsLoading = createSelector(selectArticlesState, (state: ArticlesState) => state.isLoading);
