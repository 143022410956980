import { ApiError } from '@core/models/api-error.modes';
import { RefreshTokenPayload, RefreshTokenResponse } from '@core/models/refresh-token.models';
import { SignInPayload, SignInResponse } from '@core/models/sign-in.models';
import { SignUpPayload, SignUpResponse } from '@core/models/sign-up.models';
import { createAction, props } from '@ngrx/store';

export const signUp = createAction('[Auth API] Sign Up', props<{ payload: SignUpPayload }>());
export const signUpSuccess = createAction('[Auth API] Sign Up Success', props<{ response: SignUpResponse }>());
export const signUpFailure = createAction('[Auth API] Sign Up Failure', props<{ error: ApiError }>());

export const signIn = createAction('[Auth API] Sign In', props<{ payload: SignInPayload }>());
export const signInSuccess = createAction('[Auth API] Sign In Success', props<{ response: SignInResponse }>());
export const signInFailure = createAction('[Auth API] Sign In Failure', props<{ error: ApiError }>());

export const authenticate = createAction('[Auth API] Authenticate', props<{ payload: string }>());
export const authenticateSuccess = createAction(
  '[Auth API] Authenticate Success',
  props<{ response: SignInResponse }>()
);
export const authenticateFailure = createAction('[Auth API] Authenticate Failure', props<{ error: ApiError }>());

export const refreshToken = createAction('[Auth API] Refresh Token');
export const refreshTokenSuccess = createAction(
  '[Auth API] Refresh Token Success',
  props<{ response: RefreshTokenResponse }>()
);
export const refreshTokenFailure = createAction('[Auth API] Refresh Token Failure', props<{ error: ApiError }>());

export const logOut = createAction('[Auth API] Log Out', props<{ withRedirect?: boolean }>());
export const logOutSuccess = createAction('[Auth API] Log Out Success');
