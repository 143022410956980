import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GgEnvironment } from '@core/models/environment.interfaces';
import { ENVIRONMENT } from '@core/constants/platform/environment.token';
import { APP_VERSION } from '@core/constants/platform/app-version.token';

@Component({
  selector: 'gg-version-renderer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './version-renderer.component.html',
  styleUrls: ['./version-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionRendererComponent {
  protected isProd: boolean = false;

  constructor(@Inject(ENVIRONMENT) environment: GgEnvironment, @Inject(APP_VERSION) public appVersion: string) {
    this.isProd = environment.production;

    if (this.isProd) {
      console.log(appVersion);
    }
  }
}
