import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DEFAULT_SEARCH_PARAMS } from '@core/constants/search-params.constants';
import { ClassArea, CreateClassAreaPayload } from '@core/models/class-area.models';
import { map, Observable } from 'rxjs';

const FEATURE: string = 'area';

@Injectable({ providedIn: 'root' })
export class ClassAreaApiService {
  constructor(private readonly http: HttpClient) {}

  get(): Observable<ClassArea[]> {
    // TODO: need to fix api on backend (make response data as simple array)
    const queryParams: HttpParams = new HttpParams().appendAll(DEFAULT_SEARCH_PARAMS as any);

    return this.http.get<ClassArea[]>(FEATURE, { params: queryParams }).pipe(map(({ data }: any) => data));
  }

  getOne(id: string): Observable<ClassArea> {
    return this.http.get<ClassArea>(`${FEATURE}/${id}`);
  }

  createClassType(type: CreateClassAreaPayload): Observable<ClassArea> {
    return this.http.post<ClassArea>(FEATURE, type);
  }
}
