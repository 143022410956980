import { NgModule } from '@angular/core';
import { AuthApiService } from '@core/services/api/auth-api.service';
import { environment } from '@environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers, reducers } from './app/app.state';
import { AreaEffects } from './area';
import { ArticlesEffects } from './articles';
import { AuthEffects } from './auth/auth.effects';
import { ClassesEffects } from './classes';
import { ClientsEffects } from './clients';
import { CurrentRecommendationEffects } from './current-recommendation';
import { OfficesEffects } from './offices';
import { SubscriptionEffects } from './subscription';
import { UserEffects } from './user/user.effects';
import { UserFacade } from './user/user.facade';

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, { metaReducers, runtimeChecks: { strictStateImmutability: false } }),
    EffectsModule.forRoot([
      AuthEffects,
      UserEffects,
      ClassesEffects,
      SubscriptionEffects,
      ClientsEffects,
      OfficesEffects,
      ArticlesEffects,
      CurrentRecommendationEffects,
      AreaEffects,
    ]),
    environment.production ? [] : StoreDevtoolsModule.instrument({ maxAge: 25 }),
  ],
})
export class RootStoreModule {
  constructor(private userFacade: UserFacade, private authApi: AuthApiService) {
    if (this.authApi.isAuthenticated()) {
      this.userFacade.loadUser();
    }
  }
}
