<svg
  width="16px"
  height="12px"
  viewBox="0 0 16 12"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/card-blue</title>
  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="icons/card-blue" transform="translate(-1.000000, -3.000000)">
      <rect
        id="Rectangle"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        x="2"
        y="4"
        width="14"
        height="10"
        rx="2"
      ></rect>
      <rect id="Rectangle" fill="currentColor" x="2" y="6" width="14" height="2"></rect>
    </g>
  </g>
</svg>
