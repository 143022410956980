import { Injectable } from '@angular/core';
import { FailureAction, SuccessAction } from '@core/models/store-actions.models';
import { Actions, ofType } from '@ngrx/effects';
import { ActionCreator } from '@ngrx/store';
import { map, merge, Observable, switchMap, take, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StoreActionResponseApiService {
  constructor(private readonly actions$: Actions) {}

  actionResponse<T>(successAction: ActionCreator, failureAction: ActionCreator): Observable<T> {
    const successActionHandler: Observable<T> = this.successActionHandler<T>(successAction);
    const failureActionHandler: Observable<any> = this.failureActionHandler(failureAction);
    return merge(successActionHandler, failureActionHandler).pipe(take(1));
  }

  private successActionHandler<T>(successAction: ActionCreator): Observable<T> {
    return this.getActionObserver<SuccessAction<T>>(successAction).pipe(
      map((action: SuccessAction<T>) => action.response)
    );
  }

  private failureActionHandler(failureAction: ActionCreator): Observable<any> {
    return this.getActionObserver<FailureAction>(failureAction).pipe(
      switchMap((action: FailureAction) => throwError(() => action.error))
    );
  }

  private getActionObserver<T>(action: ActionCreator): Observable<T> {
    return this.actions$.pipe(ofType(action));
  }
}
