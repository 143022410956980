import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'gg-engagement-icon',
  templateUrl: './engagement-icon.component.html',
  styleUrls: ['./engagement-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementIconComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
