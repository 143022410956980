<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/menu/Book Services</title>
  <g id="icons/menu/Book-Services" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Group">
      <path
        d="M2,5 L16,5 L16,7.77479076 L16,14.1786855 L16,15 C16,15.5522847 15.5522847,16 15,16 L3,16 C2.44771525,16 2,15.5522847 2,15 L2,5 L2,5 Z"
        id="Rectangle"
        stroke="currentColor"
        stroke-width="2"
      ></path>
      <rect id="Rectangle" fill="currentColor" x="9" y="11" width="5" height="2" rx="1"></rect>
      <path
        d="M13,5 C13,2.790861 11.209139,1 9,1 C6.790861,1 5,2.790861 5,5"
        id="Path"
        stroke="currentColor"
        stroke-width="2"
      ></path>
    </g>
  </g>
</svg>
