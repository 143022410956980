import { WlcmQueryParams } from '@wlcm/common';
import { BaseEntity } from './base-entity.models';
import { Charge } from './charge.models';
import { Client } from './client.models';
import { Company } from './company.models';
import { Coupon } from './coupon.models';
import { Credit } from './credits.models';
import { Invite } from './invite.models';
import { PaymentMethod } from './payment.models';
import { Subscription } from './subscription.models';

export interface User extends BaseEntity {
  full_name: string;
  email: string;
  type: UserType;
  name: string;
  photo: string;
  sign_up_date: string | null;
  stripe_customer_id?: string;
  is_verified: boolean;
  subscription?: Subscription;
  subscriptions?: string[];
  has_future_bookings: boolean;
  default_stripe_card_id?: string;
  signature?: string;
  company?: Company;
  invited_by?: User;
}

export interface UserIntro extends Pick<User, 'full_name' | 'email' | 'name' | 'type'> {
  password: string;
}

export interface AccountInfo {
  user: User;
  coupon: Coupon;
  invites: Invite[];
  subscription: Subscription;
  paymentMethods: PaymentMethod[];
  clients: Client[];
  credits: Credit[];
  charges: Charge[];
}

export enum UserType {
  Admin = 'admin',
  Client = 'client',
  InsuranceBroker = 'insurance_broker',
  InsuranceCarrier = 'insurance_carrier',
}

export interface GetUsersSearchParams extends WlcmQueryParams {
  company?: string[];
  account_type?: string[];
}
