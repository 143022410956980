import { BaseEntity } from './base-entity.models';

export class GgSelectOption<T = unknown> {
  constructor(
    public value: string = '',
    public viewValue: string = '',
    public selected: boolean = false,
    public disabled: boolean = false,
    public data?: T
  ) {}
}

export class GgAutocompleteOption<T = unknown> extends GgSelectOption {
  constructor(
    public override value: string = '',
    public override viewValue: string = '',
    public searchValue: string = '',
    public override data?: T
  ) {
    super(value, viewValue);

    this.searchValue = searchValue || viewValue;
  }
}

export interface GgBaseOption extends BaseEntity {
  name?: string;
  title?: string;
}
