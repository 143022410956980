import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'gg-datepicker-icon',
  templateUrl: './datepicker-icon.component.html',
  styleUrls: ['./datepicker-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerIconComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
