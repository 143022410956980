<svg
  width="5px"
  height="8px"
  viewBox="0 0 5 8"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/arrow white right</title>
  <g id="icons/arrow-white-right" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path
      d="M0.29217099,7.70638381 C-0.0679444002,7.34553166 -0.0950942609,6.77827256 0.210331358,6.38629327 L0.293616192,6.29217099 L2.585,4.00537751 L0.292172463,1.70638529 C-0.06794368,1.34553388 -0.095094723,0.778274843 0.210330078,0.386294913 L0.293614717,0.292172463 C0.654466123,-0.06794368 1.22172516,-0.095094723 1.61370509,0.210330078 L1.70782754,0.293614717 L4.70782754,3.29973993 C5.06794422,3.66059187 5.09509474,4.22785192 4.78966876,4.61983181 L4.70638381,4.71395423 L1.70638381,7.70782902 C1.31546064,8.09795402 0.682295997,8.09730698 0.29217099,7.70638381 Z"
      id="Path"
      fill="currentColor"
      fill-rule="nonzero"
    ></path>
  </g>
</svg>
