<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/menu/account settings</title>
  <g id="1.0-Broker-or-Carrier" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="1.0.0-Dashboard-Calendar-View" transform="translate(-16.000000, -976.000000)">
      <g id="Group" transform="translate(16.000000, 976.000000)">
        <circle id="Oval" stroke="#FFFFFF" stroke-width="2" cx="6" cy="4" r="3"></circle>
        <rect id="Rectangle" fill="#FFFFFF" x="8" y="3" width="10" height="2" rx="1"></rect>
        <rect id="Rectangle" fill="#FFFFFF" x="0" y="3" width="3" height="2" rx="1"></rect>
        <circle
          id="Oval"
          stroke="#FFFFFF"
          stroke-width="2"
          transform="translate(12.000000, 14.000000) scale(-1, 1) translate(-12.000000, -14.000000) "
          cx="12"
          cy="14"
          r="3"
        ></circle>
        <rect
          id="Rectangle"
          fill="#FFFFFF"
          transform="translate(5.000000, 14.000000) scale(-1, 1) translate(-5.000000, -14.000000) "
          x="0"
          y="13"
          width="10"
          height="2"
          rx="1"
        ></rect>
        <rect
          id="Rectangle"
          fill="#FFFFFF"
          transform="translate(16.500000, 14.000000) scale(-1, 1) translate(-16.500000, -14.000000) "
          x="15"
          y="13"
          width="3"
          height="2"
          rx="1"
        ></rect>
      </g>
    </g>
  </g>
</svg>
