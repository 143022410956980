import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { GgPopupConfig } from '@core/models/popup.models';
import { PopupApiService } from '@core/services/api/popup-api.service';
import { BookingNotAllowedDialogComponent } from '@shared/modules/book-class/components/booking-not-allowed-dialog/booking-not-allowed-dialog.component';
import { BOOKING_NOT_ALLOWED_POPUP_CONFIG } from '@shared/modules/book-class/constants/booking-not-allowed.constants';

@Component({
  selector: 'gg-book-class-button',
  templateUrl: './book-class-button.component.html',
  styleUrls: ['./book-class-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookClassButtonComponent {
  @Input() label: string = 'Book';

  @Input() isNonBookable: boolean = false;

  @Output() book: EventEmitter<void> = new EventEmitter();

  constructor(private popupApi: PopupApiService) {}

  contactUs(): void {
    const title: string = 'Contact us';
    const config: GgPopupConfig = this.popupApi.patchContentConfig(BOOKING_NOT_ALLOWED_POPUP_CONFIG, { title });
    this.popupApi.openCustomPopup(BookingNotAllowedDialogComponent, config);
  }
}
