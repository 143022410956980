<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" version="1.1"
     id="icon" viewBox="0 0 32 32" xml:space="preserve">
<style type="text/css">
	.st0 {
    fill: none;
  }
</style>
  <title>draggable</title>
  <rect x="10" y="6" width="4" height="4" />
  <rect x="18" y="6" width="4" height="4" />
  <rect x="10" y="14" width="4" height="4" />
  <rect x="18" y="14" width="4" height="4" />
  <rect x="10" y="22" width="4" height="4" />
  <rect x="18" y="22" width="4" height="4" />
  <rect id="_Transparent_Rectangle_" class="st0" width="32" height="32" />
</svg>
