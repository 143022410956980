import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'gg-chevron-right-icon',
  templateUrl: './chevron-right-icon.component.html',
  styleUrls: ['./chevron-right-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChevronRightIconComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
