import { Injectable } from '@angular/core';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@core/constants/token.constants';
import { ForgotPasswordPayload } from '@core/models/forgot-password.models';
import { GgHttpGetOptions } from '@core/models/http.models';
import { RefreshTokenPayload, RefreshTokenResponse } from '@core/models/refresh-token.models';
import { ResetPasswordPayload } from '@core/models/reset-password.models';
import { SignInPayload, SignInResponse } from '@core/models/sign-in.models';
import { SignUpPayload, SignUpResponse } from '@core/models/sign-up.models';
import { ChangePasswordPayload } from '@features/account-settings/models/change-password.models';
import { AuthState, AUTH_FEATURE_KEY } from '@store/auth';
import { Observable } from 'rxjs';
import { HttpApiService } from './http-api.service';

const FEATURE: string = 'auth';

@Injectable({ providedIn: 'root' })
export class AuthApiService {
  constructor(private readonly httpApi: HttpApiService) {}

  signUp(payload: SignUpPayload): Observable<SignUpResponse> {
    return this.httpApi.post<SignUpResponse>(`${FEATURE}/sign-up`, payload, { skipSuccessMessage: true });
  }

  signIn(payload: SignInPayload): Observable<SignInResponse> {
    return this.httpApi.post<SignInResponse>(`${FEATURE}/login`, payload, { skipSuccessMessage: true });
  }

  forgotPassword(payload: ForgotPasswordPayload): Observable<boolean> {
    return this.httpApi.post<boolean>(`${FEATURE}/forgot-password`, payload);
  }

  resetPassword(payload: ResetPasswordPayload, options?: GgHttpGetOptions): Observable<boolean> {
    return this.httpApi.patch<boolean>(`${FEATURE}/reset-password`, payload, options);
  }

  changePassword(payload: ChangePasswordPayload): Observable<boolean> {
    return this.httpApi.patch<boolean>(`${FEATURE}/change-password`, payload);
  }

  resendEmail(): Observable<unknown> {
    return this.httpApi.post<unknown>(`${FEATURE}/resend-activation-email`, {});
  }

  refreshToken(payload: RefreshTokenPayload): Observable<RefreshTokenResponse> {
    return this.httpApi.post<RefreshTokenResponse>(`${FEATURE}/new-access-token`, payload, {
      skipSuccessMessage: true,
    });
  }

  getAccessToken(): string | null {
    return this.getAuthState()?.[ACCESS_TOKEN_KEY] || null;
  }

  getRefreshToken(): string | null {
    return this.getAuthState()?.[REFRESH_TOKEN_KEY] || null;
  }

  isAuthenticated(): boolean {
    return !!this.getAccessToken();
  }

  private getAuthState(): AuthState {
    return JSON.parse(localStorage.getItem(AUTH_FEATURE_KEY) || '');
  }
}
