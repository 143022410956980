import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DeleteRecContentPayload,
  PatchRecommendationPayload,
  Recommendation,
} from '@core/models/recommendation.models';
import { Observable } from 'rxjs';

const FEATURE: string = 'recommendation';

@Injectable({ providedIn: 'root' })
export class RecommendationApiService {
  constructor(private readonly http: HttpClient) {}

  get(): Observable<Recommendation[]> {
    return this.http.get<Recommendation[]>(`${FEATURE}`);
  }

  getCurrent(clientId: string): Observable<Recommendation> {
    return this.http.get<Recommendation>(`${FEATURE}/current/${clientId}`);
  }

  patch(payload: PatchRecommendationPayload): Observable<Recommendation> {
    return this.http.patch<Recommendation>(`${FEATURE}`, payload);
  }

  deleteContent(payload: DeleteRecContentPayload): Observable<Recommendation> {
    return this.http.patch<Recommendation>(`${FEATURE}/content`, payload);
  }
}
