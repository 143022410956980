import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AUTH_CONTENT_ROUTE_CONFIG } from '@core/constants/routes.constants';
import { Router } from '@angular/router';

@Component({
  selector: 'gg-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent {
  constructor(private router: Router) {}

  goToHomePage(): void {
    this.router.navigateByUrl(AUTH_CONTENT_ROUTE_CONFIG.root);
  }
}
