<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/menu/Wellness Offerings</title>
  <g id="icons/menu/Content-Library" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path
      d="M14.9734679,17 L14.9734679,17 L2,17 C1.44771525,17 1,16.5522847 1,16 L1,4.10893917 L1,4.10893917"
      id="Path"
      stroke="#FFFFFF"
      stroke-width="2"
      stroke-linecap="round"
    ></path>
    <rect id="Rectangle" stroke="#FFFFFF" stroke-width="2" x="4" y="1" width="13" height="13" rx="1"></rect>
    <path
      d="M9,5.92032651 L9,9.10991119 C9,9.66219594 9.44771525,10.1099112 10,10.1099112 C10.2054055,10.1099112 10.4058284,10.0466581 10.5740226,9.92875066 L12.8489835,8.33395832 C13.301216,8.0169344 13.4108243,7.39332882 13.0938003,6.94109627 C13.0270498,6.84587702 12.9442027,6.76302995 12.8489835,6.69627938 L10.5740226,5.10148704 C10.12179,4.78446312 9.49818445,4.89407138 9.18116053,5.34630393 C9.06325313,5.51449807 9,5.71492099 9,5.92032651 Z"
      id="Path-2"
      stroke="#FFFFFF"
      stroke-width="2"
    ></path>
  </g>
</svg>
