import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USER_FEATURE_KEY } from './user.reducer';
import { UserState } from './user.state';

const selectUserState = createFeatureSelector<UserState>(USER_FEATURE_KEY);

const getUser = (state: UserState) => state.data;
const getIsLoadedFlag = (state: UserState) => state.isLoaded;
const getIsLoadingFlag = (state: UserState) => state.isLoading;

export const selectUser = createSelector(selectUserState, getUser);
export const selectIsLoaded = createSelector(selectUserState, getIsLoadedFlag);
export const selectIsLoading = createSelector(selectUserState, getIsLoadingFlag);
