import { ApiError } from '@core/models/api-error.modes';
import { ClientOffice, ClientOfficePayload } from '@core/models/client.models';
import { AddCompanyOfficePayload } from '@core/models/company.models';
import { createAction, props } from '@ngrx/store';

export const loadOffices = createAction('[Office API] Load Offices');
export const loadOfficesSuccess = createAction(
  '[Office API] Load Offices Success',
  props<{ response: ClientOffice[] }>()
);
export const loadOfficesFailure = createAction('[Office API] Load Offices Failure', props<{ error: ApiError }>());

export const retrieveLatestOfficeData = createAction(
  '[Office API] Retrieve Latest Office Data',
  props<{ payload: string }>()
);
export const retrieveLatestOfficeDataSuccess = createAction(
  '[Office API] Retrieve Latest Office Data Success',
  props<{ response: ClientOffice }>()
);
export const retrieveLatestOfficeDataFailure = createAction(
  '[Office API] Retrieve Latest Office Data Failure',
  props<{ error: ApiError }>()
);

export const addOffice = createAction('[Office API] Add Offices', props<{ payload: AddCompanyOfficePayload }>());
export const addOfficeSuccess = createAction('[Office API] Add Offices Success', props<{ response: ClientOffice }>());
export const addOfficeFailure = createAction('[Office API] Add Offices Failure', props<{ error: ApiError }>());

export const patchOffice = createAction(
  '[Office API] Patch Offices',
  props<{ payload: Partial<ClientOfficePayload> }>()
);
export const patchOfficeSuccess = createAction(
  '[Office API] Patch Offices Success',
  props<{ response: ClientOffice }>()
);
export const patchOfficeFailure = createAction('[Office API] Patch Offices Failure', props<{ error: ApiError }>());

export const deleteOffice = createAction(
  '[Office API] Delete Offices',
  props<{ companyId: string; payload: string }>()
);
export const deleteOfficeSuccess = createAction('[Office API] Delete Offices Success', props<{ response: string }>());
export const deleteOfficeFailure = createAction('[Office API] Delete Offices Failure', props<{ error: ApiError }>());

export const selectOffice = createAction('[Office API] Select Office', props<{ payload: string }>());

export const reset = createAction('[Office API] Reset');
