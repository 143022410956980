import { Injectable } from '@angular/core';
import { ArticleApiService } from '@core/services/api/article-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ArticlesState } from './articles.state';
import { BaseEffect } from '@store/base/base.models';

import * as fromActions from './articles.actions';
import * as fromSelectors from './articles.selectors';

import { Observable, switchMap } from 'rxjs';
import { LoadArticlesPayload } from '@core/models/article.models';

@Injectable()
export class ArticlesEffects extends BaseEffect<ArticlesState> {
  constructor(
    private readonly actions$: Actions,
    private readonly articleApi: ArticleApiService,
    public readonly store: Store<ArticlesState>
  ) {
    super(store);
  }

  loadArticles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadArticles),
      this.mapSearchParams(fromSelectors.selectSearchParams),
      switchMap((payload: LoadArticlesPayload) =>
        this.articleApi
          .get(payload)
          .pipe(this.handleResponse(fromActions.loadArticlesSuccess, fromActions.loadArticlesFailure))
      )
    )
  );
}
