import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormBuilder,
  UntypedFormControl,
  FormControlOptions,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';

export type GgFGControlsConfig<T> = { [key in keyof T]: any };

export class GgFormGroup<T> extends UntypedFormGroup {
  controls: { [key in keyof T]: AbstractControl };

  value: T;

  patchValue(value: Partial<T>, options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.patchValue(value, options);
  }
}

export class GgFormControl<T = string> extends UntypedFormControl {
  private readonly _isTouched$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly isTouched$: Observable<boolean> = this._isTouched$.asObservable().pipe(shareReplay());

  value: T;

  updateValueAndValidityFn: () => void = () => {};

  markAsTouched(opts?: { onlySelf?: boolean }): void {
    this._isTouched$.next(true);
    super.markAsTouched(opts);
  }

  markAsUntouched(opts?: { onlySelf?: boolean }): void {
    this._isTouched$.next(false);
    super.markAsUntouched(opts);
  }

  updateValueAndValidity(opts?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.updateValueAndValidity(opts);

    if (this.updateValueAndValidityFn instanceof Function) {
      this.updateValueAndValidityFn();
    }
  }
}

@Injectable()
export class GgFormBuilder extends UntypedFormBuilder {
  control(
    formState: any,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | FormControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ): GgFormControl {
    return new GgFormControl(formState, validatorOrOpts, asyncValidator);
  }
}
