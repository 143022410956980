<svg
  width="5px"
  height="8px"
  viewBox="0 0 5 8"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/arrow black left</title>
  <g id="1.0-Broker-or-Carrier" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g
      id="1.0.0-Dashboard-Calendar-View"
      transform="translate(-229.000000, -1015.000000)"
      fill="currentColor"
      fill-rule="nonzero"
    >
      <g id="Group-8" transform="translate(225.000000, 1015.000000)">
        <g id="Path" transform="translate(4.000000, 0.000000)">
          <path
            d="M-1.20638381,1.79217099 C-0.845531651,1.4320556 -0.278272554,1.40490574 0.113706739,1.71033136 L0.207829016,1.79361619 L2.49462249,4.085 L4.79361472,1.79217246 C5.15446612,1.43205632 5.72172516,1.40490528 6.11370509,1.71033008 L6.20782754,1.79361472 C6.56794369,2.15446612 6.59509473,2.72172516 6.28966993,3.11370509 L6.20638529,3.20782754 L3.20026007,6.20782754 C2.83940814,6.56794422 2.27214809,6.59509474 1.88016819,6.28966876 L1.78604577,6.20638381 L-1.20782901,3.20638381 C-1.59795402,2.81546064 -1.59730698,2.182296 -1.20638381,1.79217099 Z"
            transform="translate(2.500000, 4.000000) scale(-1, 1) rotate(-90.000000) translate(-2.500000, -4.000000) "
          ></path>
        </g>
      </g>
    </g>
  </g>
</svg>
