<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/delete</title>
  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="icons/delete">
      <path
        d="M4,4 L14,4 L12.1492936,14.1788854 C12.0628407,14.6543761 11.6487098,15 11.1654236,15 L6.83457635,15 C6.35129021,15 5.9371593,14.6543761 5.85070644,14.1788854 L4,4 L4,4 Z"
        id="Rectangle"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
      ></path>
      <rect id="Rectangle" fill="currentColor" x="1" y="3" width="16" height="2" rx="1"></rect>
      <rect id="Rectangle" fill="currentColor" x="6" y="1" width="6" height="2" rx="1"></rect>
      <rect id="Rectangle" fill="currentColor" x="5" y="11" width="8" height="2" rx="1"></rect>
    </g>
  </g>
</svg>
