<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="icons/menu/Video-library" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <rect id="Rectangle" x="0" y="0" width="18" height="18"></rect>
    <g id="Group" transform="translate(1, 1)" stroke="#FFFFFF" stroke-width="2">
      <path
        d="M6.33333333,6 L6.33333333,10 C6.33333333,10.5522847 6.78104858,11 7.33333333,11 C7.54970355,11 7.76023716,10.9298221 7.93333333,10.8 L10.6,8.8 C11.0418278,8.46862915 11.1313708,7.8418278 10.8,7.4 C10.7431458,7.32419433 10.6758057,7.25685425 10.6,7.2 L7.93333333,5.2 C7.49150553,4.86862915 6.86470418,4.9581722 6.53333333,5.4 C6.40351121,5.57309617 6.33333333,5.78362979 6.33333333,6 Z"
        id="Path-2"
      ></path>
      <circle id="Oval" cx="8" cy="8" r="8"></circle>
    </g>
  </g>
</svg>
