<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/remove blue</title>
  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="icons/remove-blue">
      <rect id="Rectangle" x="-1" y="-1" width="20" height="20"></rect>
      <path
        fill="currentColor"
        d="M14.6568542,3.34314575 C15.0473785,3.73367004 15.0473785,4.36683502 14.6568542,4.75735931 L10.4142136,9 L14.6568542,13.2426407 C15.0473785,13.633165 15.0473785,14.26633 14.6568542,14.6568542 C14.26633,15.0473785 13.633165,15.0473785 13.2426407,14.6568542 L9,10.4142136 L4.75735931,14.6568542 C4.36683502,15.0473785 3.73367004,15.0473785 3.34314575,14.6568542 C2.95262146,14.26633 2.95262146,13.633165 3.34314575,13.2426407 L7.58578644,9 L3.34314575,4.75735931 C2.95262146,4.36683502 2.95262146,3.73367004 3.34314575,3.34314575 C3.73367004,2.95262146 4.36683502,2.95262146 4.75735931,3.34314575 L9,7.58578644 L13.2426407,3.34314575 C13.633165,2.95262146 14.26633,2.95262146 14.6568542,3.34314575 Z"
        id="Path"
      ></path>
    </g>
  </g>
</svg>
