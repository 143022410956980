<svg
  width="8px"
  height="5px"
  viewBox="0 0 8 5"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/arrow black down</title>
  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g
      id="fields/dropdown-req-empty"
      transform="translate(-254.000000, -35.000000)"
      fill="currentColor"
      fill-rule="nonzero"
    >
      <g id="Path" transform="translate(254.000000, 35.000000)">
        <path
          d="M1.79217099,6.20638381 C1.4320556,5.84553166 1.40490574,5.27827256 1.71033136,4.88629327 L1.79361619,4.79217099 L4.085,2.50537751 L1.79217246,0.20638529 C1.43205632,-0.154466116 1.40490528,-0.721725157 1.71033008,-1.11370509 L1.79361472,-1.20782754 C2.15446612,-1.56794368 2.72172516,-1.59509472 3.11370509,-1.28966992 L3.20782754,-1.20638528 L6.20782754,1.79973993 C6.56794422,2.16059187 6.59509474,2.72785192 6.28966876,3.11983181 L6.20638381,3.21395423 L3.20638381,6.20782902 C2.81546064,6.59795402 2.182296,6.59730698 1.79217099,6.20638381 Z"
          transform="translate(4.000000, 2.500000) rotate(-270.000000) translate(-4.000000, -2.500000) "
        ></path>
      </g>
    </g>
  </g>
</svg>
