import { Injectable } from '@angular/core';
import { AuthApiService } from '@core/services/api/auth-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  asyncScheduler,
  EMPTY,
  exhaustMap,
  filter,
  map,
  Observable,
  observeOn,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { NavigationEnd, Router, Event } from '@angular/router';
import { AUTH_ROUTE_CONFIG } from '@core/constants/routes.constants';

import * as authActions from './auth.actions';
import * as authSelectors from './auth.selectors';
import * as userActions from '@store/user/user.actions';

import { BaseEffect } from '@store/base/base.models';
import { PopupApiService } from '@core/services/api/popup-api.service';
import { InviteApiService } from '@core/services/api/invite-api.service';
import { SuccessfulAuthorization } from '@core/models/auth.models';

@Injectable()
export class AuthEffects extends BaseEffect {
  constructor(
    public store: Store,
    private router: Router,
    private actions$: Actions,
    private authApi: AuthApiService,
    private inviteApi: InviteApiService,
    private popupApi: PopupApiService
  ) {
    super(store);
  }

  signUp$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.signUp),
      exhaustMap((action) =>
        this.authApi
          .signUp(action.payload)
          .pipe(this.successfullyAuthorized())
          .pipe(this.handleResponse(authActions.signUpSuccess, authActions.signUpFailure))
      )
    )
  );

  signIn$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.signIn),
      exhaustMap((action) =>
        this.authApi
          .signIn(action.payload)
          .pipe(this.successfullyAuthorized())
          .pipe(this.handleResponse(authActions.signInSuccess, authActions.signInFailure))
      )
    )
  );

  authenticate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.authenticate),
      exhaustMap((action) =>
        this.inviteApi
          .authenticate(action.payload)
          .pipe(this.successfullyAuthorized())
          .pipe(this.handleResponse(authActions.authenticateSuccess, authActions.authenticateFailure))
      )
    )
  );

  refreshToken$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.refreshToken),
      switchMap(() => this.store.select(authSelectors.selectRefreshToken)),
      exhaustMap((refreshToken: string) =>
        this.authApi
          .refreshToken({ refreshToken })
          .pipe(this.handleResponse(authActions.refreshTokenSuccess, authActions.refreshTokenFailure))
      )
    )
  );

  logOut$: any = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logOut),
      tap(() => this.popupApi.closeAll()),
      switchMap((action) => {
        if (action.withRedirect) {
          this.router.navigate([AUTH_ROUTE_CONFIG.root]);

          return this.router.events
            .pipe(
              filter((event: Event) => event instanceof NavigationEnd),
              take(1)
            )
            .pipe(map(() => authActions.logOutSuccess()));
        }

        return EMPTY.pipe(startWith(null))
          .pipe(observeOn(asyncScheduler))
          .pipe(map(() => authActions.logOutSuccess()));
      })
    )
  );

  private successfullyAuthorized = (): ((
    source: Observable<SuccessfulAuthorization>
  ) => Observable<SuccessfulAuthorization>) => {
    return (source: Observable<SuccessfulAuthorization>): Observable<SuccessfulAuthorization> => {
      return source.pipe(
        tap((response: SuccessfulAuthorization) => this.store.dispatch(userActions.setUser({ payload: response.user })))
      );
    };
  };
}
