<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/menu/Content Library</title>
  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="icons/menu/Wellness-Offerings">
      <path
        d="M11,1 C13.209139,1 15,2.790861 15,5 C15,6.19228028 14.478358,7.26272456 13.6508508,7.99555611 L8,13.6568542 L2.34314575,8 C1.52121447,7.26184712 1,6.19179164 1,5 C1,2.790861 2.790861,1 5,1 C6.19228028,1 7.26272456,1.52164196 7.99555611,2.34914916 L8,2.34314575 L8.10653313,2.23813406 C8.79207415,1.52013554 9.74216406,1.05690822 10.8003597,1.00489531 L11,1 Z"
        id="Path"
        stroke="#FFFFFF"
        stroke-width="2"
      ></path>
      <rect id="Rectangle" fill="#FFFFFF" x="0" y="16" width="18" height="2" rx="1"></rect>
      <rect id="Rectangle" fill="#FFFFFF" x="12" y="12" width="6" height="2" rx="1"></rect>
    </g>
  </g>
</svg>
