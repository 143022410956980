import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { forwardRef, NgModule, Optional, SkipSelf } from '@angular/core';
import { blockDialogScrolling, MaterialModule } from '@material/material.module';
import { ApiTokenInterceptor } from './services/interceptors/api-token.interceptor';
import { ApiUrlnterceptor } from './services/interceptors/api-url.interceptor';
import { ErrorInterceptor } from './services/interceptors/error.interceptor';
import { WLCM_ICONS_CONFIG } from '@shared/constants/icons.constants';
import { WLCM_ICONS } from '@wlcm/angular/core';
import { FORM_ERRORS } from './constants/form-errors.constants';
import { WLCM_ERRORS } from '@wlcm/angular/forms';
import { Overlay, BlockScrollStrategy } from '@angular/cdk/overlay';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MAT_DIALOG_SCROLL_STRATEGY } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { DEFAULT_DIALOG_CONFIG } from './constants/dialog.constants';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [CommonModule, MaterialModule, HttpClientModule],
  providers: [
    { provide: WLCM_ICONS, useValue: WLCM_ICONS_CONFIG },
    { provide: WLCM_ERRORS, useExisting: forwardRef(() => FORM_ERRORS) },
    { provide: HTTP_INTERCEPTORS, useClass: ApiUrlnterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiTokenInterceptor, multi: true },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useFactory: (config: MatDialogConfig) => config,
      deps: [DEFAULT_DIALOG_CONFIG],
    },
    {
      provide: MAT_DIALOG_SCROLL_STRATEGY,
      useFactory: blockDialogScrolling,
      deps: [Overlay],
    },
    {
      provide: MAT_MENU_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => (): BlockScrollStrategy => overlay.scrollStrategies.block(),
      deps: [Overlay],
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },

    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        verticalPosition: 'bottom',
        horizontalPosition: 'end',
      },
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
