import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  APP_ROUTE_CONFIG,
  ASSIGNMENT_ROUTE_CONFIG,
  AUTH_CONTENT_ROUTE_CONFIG,
  AUTH_ROUTE_CONFIG,
  SIGN_UP_ROUTE_CONFIG,
} from '@core/constants/routes.constants';
import { AuthGuard } from '@core/guards/auth.guard';
import { DefaultRouteGuard } from '@core/guards/default-route.guard';
import { SubscribedUserGuard } from '@core/guards/subscribed-user.guard';
import { PreloadingStrategyService } from '@core/services/preloading-strategy.service';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: AUTH_ROUTE_CONFIG.root,
    loadChildren: () => import('@features/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: SIGN_UP_ROUTE_CONFIG.root,
    loadChildren: () => import('@features/sign-up/sign-up.module').then((m) => m.SignUpModule),
  },
  {
    path: ASSIGNMENT_ROUTE_CONFIG.root,
    loadChildren: () => import('@features/assignment/assignment.module').then((m) => m.AssignmentModule),
  },
  {
    path: AUTH_CONTENT_ROUTE_CONFIG.root,
    loadChildren: () =>
      import('@features/authorized-content/authorized-content.module').then((m) => m.AuthorizedContentModule),
    canLoad: [AuthGuard, SubscribedUserGuard],
    canActivate: [DefaultRouteGuard, SubscribedUserGuard],
  },
  { path: APP_ROUTE_CONFIG.children.notFound, component: PageNotFoundComponent },
  { path: '**', redirectTo: APP_ROUTE_CONFIG.children.notFound, pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadingStrategyService,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
