import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState, AUTH_FEATURE_KEY } from '.';

const selectAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

const getAccessToken = (state: AuthState) => state.accessToken;
const getRefreshToken = (state: AuthState) => state.refreshToken;

export const selectAccessToken = createSelector(selectAuthState, getAccessToken);
export const selectRefreshToken = createSelector(selectAuthState, getRefreshToken);
