export enum GgErrorType {
  MIN = 'min',
  MAX = 'max',
  EMAIL = 'email',
  REQUIRED = 'required',
  MIN_LENGTH = 'minlength',
  MAX_LENGTH = 'maxlength',
  PATTERN = 'pattern',
  DONT_MATCH = 'dontMatch',
  UNSELECTED = 'unselected',
  SPECIFIC = 'specific',
  MATCHING = 'matching',
  MAT_DATEPICKER_PARSE = 'matDatepickerParse',
  MAT_DATEPICKER_MIN = 'matDatepickerMin',
  NON_CORPORATE_DOMAIN = 'nonCorporateDomain',
  INVALID_DOMAINS = 'invalidDomains',
}
