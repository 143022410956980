import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'gg-remove-class-button',
  templateUrl: './remove-class-button.component.html',
  styleUrls: ['./remove-class-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoveClassButtonComponent implements OnInit {
  @Input() disabled: boolean;

  @Output() remove: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
