import { Injectable } from '@angular/core';
import { GgHttpBaseOptions } from '@core/models/http.models';
import {
  ChangeSubscriptionPlan,
  CreateSubscriptionPayload,
  CreateSubscriptionResponse,
  Subscription,
  SubscriptionPrices,
  UpdateSubscriptionPayload,
  UpdateSubscriptionResponse,
} from '@core/models/subscription.models';
import { Observable, map } from 'rxjs';
import { HttpApiService } from './http-api.service';

const FEATURE: string = 'subscription';

@Injectable({ providedIn: 'root' })
export class SubscriptionApiService {
  constructor(private readonly httpApi: HttpApiService) {}

  get(): Observable<Subscription> {
    return this.httpApi.get<Subscription>(FEATURE);
  }

  create(payload: CreateSubscriptionPayload, options?: GgHttpBaseOptions): Observable<CreateSubscriptionResponse> {
    return this.httpApi.post<CreateSubscriptionResponse>(FEATURE, payload, options);
  }

  update(payload: UpdateSubscriptionPayload): Observable<UpdateSubscriptionResponse> {
    return this.httpApi.patch<UpdateSubscriptionResponse>(FEATURE, payload);
  }

  delete(): Observable<unknown> {
    return this.httpApi.delete<unknown>(FEATURE);
  }

  getPrices(): Observable<SubscriptionPrices> {
    return this.httpApi.get<SubscriptionPrices>(`${FEATURE}/prices`);
  }

  calculatePrices(employees: string): Observable<ChangeSubscriptionPlan> {
    return this.httpApi.post<ChangeSubscriptionPlan>(
      `${FEATURE}/calculate-prices`,
      { employees },
      { skipSuccessMessage: true }
    );
  }

  checkUpdateEligibility(): Observable<boolean> {
    return this.httpApi.get<boolean>(`${FEATURE}/is-update-eligible`);
  }
}
