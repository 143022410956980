<svg
  width="16px"
  height="18px"
  viewBox="0 0 16 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/menu/Downloadable PDFs</title>
  <g id="1.0-Broker-or-Carrier" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="1.0.0-Dashboard-Calendar-View" transform="translate(-17.000000, -406.000000)">
      <g id="Group" transform="translate(16.000000, 406.000000)">
        <path
          d="M9,5 C9.55228475,5 10,5.44771525 10,6 L9.99937751,10.585 L11.2959922,9.29217246 C11.6568436,8.93205632 12.2241027,8.90490527 12.6160826,9.21033008 L12.7102051,9.29361471 C13.0703212,9.65446612 13.0974722,10.2217252 12.7920474,10.6137051 L12.7087628,10.7078275 L9.70263758,13.7078275 C9.34178564,14.0679442 8.77452559,14.0950947 8.3825457,13.7896688 L8.28842328,13.7063838 L5.2945485,10.7063838 C4.90442349,10.3154606 4.90507053,9.68229599 5.2959937,9.29217099 C5.65684586,8.9320556 6.22410495,8.90490574 6.61608425,9.21033135 L6.71020652,9.29361619 L7.99937751,10.586 L8,6 C8,5.44771525 8.44771525,5 9,5 Z"
          id="Combined-Shape"
          fill="currentColor"
        ></path>
        <path
          d="M3,1 L10.7903904,1 L10.7903904,1 L16,6.08839979 L16,16 C16,16.5522847 15.5522847,17 15,17 L3,17 C2.44771525,17 2,16.5522847 2,16 L2,2 C2,1.44771525 2.44771525,1 3,1 Z"
          id="Rectangle"
          stroke="currentColor"
          stroke-width="2"
        ></path>
      </g>
    </g>
  </g>
</svg>
