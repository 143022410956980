import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';

import * as fromActions from './subscription.actions';

import { SubscriptionApiService } from '@core/services/api/subscription-api.service';
import { BaseEffect } from '@store/base/base.models';

@Injectable()
export class SubscriptionEffects extends BaseEffect {
  constructor(private readonly actions$: Actions, private readonly subscriptionApi: SubscriptionApiService) {
    super();
  }

  loadAll$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAll),
      switchMap(() => [fromActions.loadSubscription(), fromActions.loadPrices()])
    )
  );

  loadSubscription$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSubscription),
      switchMap(() =>
        this.subscriptionApi
          .get()
          .pipe(this.handleResponse(fromActions.loadSubscriptionSuccess, fromActions.loadSubscriptionFailure))
      )
    )
  );

  loadPrices$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadPrices),
      switchMap(() =>
        this.subscriptionApi
          .getPrices()
          .pipe(this.handleResponse(fromActions.loadPricesSuccess, fromActions.loadPricesFailure))
      )
    )
  );
}
