import { User } from '@core/models/user.models';

export class UserState {
  data: User;
  isLoaded: boolean;
  isLoading: boolean;
}

export const initialState: UserState = {
  data: null,
  isLoaded: false,
  isLoading: false,
};
