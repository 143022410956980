import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AUTH_ROUTE_CONFIG } from '@core/constants/routes.constants';
import { AuthApiService } from '@core/services/api/auth-api.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(private router: Router, private authApi: AuthApiService) {}

  canActivate(): boolean {
    return this.isAccessAllowed();
  }

  canLoad(): boolean {
    return this.isAccessAllowed();
  }

  private isAccessAllowed(): boolean {
    if (!this.authApi.isAuthenticated()) {
      this.router.navigate(['/', AUTH_ROUTE_CONFIG.root]);

      return false;
    }

    return true;
  }
}
