import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { ClientsState } from './clients.state';

import * as clientsActions from './clients.actions';

import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Client, ClientOffice } from '@core/models/client.models';

export const CLIENTS_FEATURE_KEY = 'clients';

const adapter: EntityAdapter<Client> = createEntityAdapter<Client>();

export const initialState: ClientsState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  selected: null,
});

export const clientsReducer: ActionReducer<ClientsState, Action> = createReducer(
  initialState,

  on(clientsActions.loadClients, (state) => ({ ...state, isLoading: true })),

  on(clientsActions.loadClientsSuccess, (state, action) =>
    adapter.setAll(action.response, { ...state, isLoaded: true, isLoading: false })
  ),

  on(clientsActions.pullOneSuccess, (state, action) => adapter.upsertOne(action.response, state)),

  on(clientsActions.addClientSuccess, (state, action) => adapter.addOne(action.response, state)),

  on(clientsActions.patchClientSuccess, (state, action) => adapter.upsertOne(action.response, state)),

  on(clientsActions.patchClientLocally, (state, action) =>
    adapter.updateOne({ id: action.payload.id, changes: action.payload }, state)
  ),

  on(clientsActions.patchOfficeLocally, (state, action) => {
    const client: Client = JSON.parse(JSON.stringify(state.entities[action.clientId]));

    const officeIndex: number = client.offices.findIndex((_office: ClientOffice) => _office.id === action.payload.id);

    client.offices[officeIndex] = { ...client.offices[officeIndex], ...action.payload };

    return adapter.updateOne({ id: client.id, changes: client }, state);
  }),

  on(clientsActions.addOfficeLocally, (state, action) => {
    const client: Client = JSON.parse(JSON.stringify(state.entities[action.clientId]));

    client.offices = [...client.offices, action.payload];

    return adapter.updateOne({ id: client.id, changes: client }, state);
  }),

  on(clientsActions.deleteOfficeLocally, (state, action) => {
    const client: Client = JSON.parse(JSON.stringify(state.entities[action.clientId]));

    client.offices = client.offices.filter((office: ClientOffice) => office.id !== action.payload);

    return adapter.updateOne({ id: client.id, changes: client }, state);
  }),

  on(clientsActions.deleteClientSuccess, (state, action) => adapter.removeOne(action.response, state)),

  on(clientsActions.selectClient, (state, action) => ({ ...state, selected: action.payload })),

  on(clientsActions.reset, () => initialState)
);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectClientsIds = selectIds;
export const selectClientsEntities = selectEntities;
export const selectAllClients = selectAll;
export const selectClientsTotal = selectTotal;
