<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/menu/Email</title>
  <g id="icons/menu/Email" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path
      d="M8.99762249,1.63654157e-09 C9.54990724,1.63654147e-09 9.99762249,0.447715252 9.99762249,1 L9.997,4.585 L11.2936147,3.29217246 C11.6544661,2.93205632 12.2217252,2.90490528 12.6137051,3.21033008 L12.7078275,3.29361472 C13.0679437,3.65446612 13.0950947,4.22172516 12.7896699,4.61370509 L12.7063853,4.70782754 L9.70026007,7.70782754 C9.33940813,8.06794422 8.77214808,8.09509474 8.38016819,7.78966876 L8.28604577,7.70638381 L5.29217099,4.70638381 C4.90204598,4.31546064 4.90269302,3.68229599 5.29361619,3.29217099 C5.65446835,2.9320556 6.22172744,2.90490574 6.61370674,3.21033136 L6.70782901,3.29361619 L7.997,4.586 L7.99762249,1 C7.99762249,0.447715252 8.44533774,1.63654167e-09 8.99762249,1.63654157e-09 Z"
      id="Combined-Shape"
      fill="#FFFFFF"
      transform="translate(9.000000, 4.000000) rotate(-90.000000) translate(-9.000000, -4.000000) "
    ></path>
    <path
      d="M16,7 C16.5522847,7 17,7.44771525 17,8 L17,16 C17,16.5522847 16.5522847,17 16,17 L2,17 C1.44771525,17 1,16.5522847 1,16 L1,8 C1,7.44771525 1.44771525,7 2,7 L9,12.1050062 L16,7 Z"
      id="Shape"
      stroke="#FFFFFF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </g>
</svg>
