<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/menu/Surveys</title>
  <g id="icons/menu/Surveys" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <rect id="Rectangle" stroke="#FFFFFF" stroke-width="2" x="1" y="1" width="6" height="6" rx="1"></rect>
    <rect id="Rectangle" fill="#FFFFFF" x="9" y="1" width="9" height="2" rx="1"></rect>
    <rect id="Rectangle" fill="#FFFFFF" x="9" y="5" width="6" height="2" rx="1"></rect>
    <rect id="Rectangle" stroke="#FFFFFF" stroke-width="2" x="1" y="11" width="6" height="6" rx="1"></rect>
    <rect id="Rectangle" fill="#FFFFFF" x="9" y="11" width="9" height="2" rx="1"></rect>
    <rect id="Rectangle" fill="#FFFFFF" x="9" y="15" width="6" height="2" rx="1"></rect>
  </g>
</svg>
