import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'gg-logout-icon',
  templateUrl: './logout-icon.component.html',
  styleUrls: ['./logout-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutIconComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
