import { Component, OnInit, ChangeDetectionStrategy, Input, ContentChild } from '@angular/core';
import { DEFAULT_POPUP_CONTENT_CONFIG } from '@core/constants/dialog.constants';
import { GgPopupContentConfig } from '@core/models/popup.models';
import { CustomPopupContentDirective } from '@shared/directives/popups/custom-popup-content.directive';

@Component({
  selector: 'gg-popup-content',
  templateUrl: './popup-content.component.html',
  styleUrls: ['./popup-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupContentComponent implements OnInit {
  @Input() config: GgPopupContentConfig = DEFAULT_POPUP_CONTENT_CONFIG;

  @ContentChild(CustomPopupContentDirective) customContent: CustomPopupContentDirective;

  constructor() {}

  ngOnInit() {}
}
