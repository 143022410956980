import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CLIENTS_FEATURE_KEY, selectAllClients, selectClientsEntities } from './clients.reducer';
import { ClientsState } from './clients.state';

const selectClientsState = createFeatureSelector<ClientsState>(CLIENTS_FEATURE_KEY);

const selectCurrentClientId = createSelector(selectClientsState, (state: ClientsState) => state.selected);

export const selectIsLoaded = createSelector(selectClientsState, (state: ClientsState) => state.isLoaded);

export const selectIsLoading = createSelector(selectClientsState, (state: ClientsState) => state.isLoading);

export const selectAll = createSelector(selectClientsState, selectAllClients);

export const selectEntities = createSelector(selectClientsState, selectClientsEntities);

export const selectCurrentClient = createSelector(
  selectEntities,
  selectCurrentClientId,
  (clientEntities, clientId) => clientId && clientEntities[clientId]
);
