<svg
  width="16px"
  height="14px"
  viewBox="0 0 16 14"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/ach</title>
  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="icons/ach" transform="translate(-1.000000, -2.000000)">
      <polygon
        id="Triangle"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        points="9 3 16 7 2 7"
      ></polygon>
      <rect id="Rectangle" fill="currentColor" x="3" y="7" width="2" height="8"></rect>
      <rect id="Rectangle" fill="currentColor" x="6" y="7" width="2" height="8"></rect>
      <rect id="Rectangle" fill="currentColor" x="10" y="7" width="2" height="8"></rect>
      <rect id="Rectangle" fill="currentColor" x="13" y="7" width="2" height="8"></rect>
      <rect id="Rectangle" fill="currentColor" x="2" y="14" width="14" height="2" rx="1"></rect>
    </g>
  </g>
</svg>
