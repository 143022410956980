<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/arrow back</title>
  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="icons/arrow-back" fill="#000000">
      <path
        d="M-0.206383808,6.79217099 C0.154468349,6.4320556 0.721727446,6.40490574 1.11370674,6.71033136 L1.20782902,6.79361619 L3.49462249,9.085 L5.79361472,6.79217246 C6.15446612,6.43205632 6.72172516,6.40490528 7.11370509,6.71033008 L7.20782754,6.79361472 C7.56794369,7.15446612 7.59509473,7.72172516 7.28966993,8.11370509 L7.20638529,8.20782754 L4.20026007,11.2078275 C3.83940814,11.5679442 3.27214809,11.5950947 2.88016819,11.2896688 L2.78604577,11.2063838 L-0.20782901,8.20638381 C-0.597954016,7.81546064 -0.597306977,7.182296 -0.206383808,6.79217099 Z"
        id="Path"
        fill-rule="nonzero"
        transform="translate(3.500000, 9.000000) scale(-1, 1) rotate(-90.000000) translate(-3.500000, -9.000000) "
      ></path>
      <rect id="Rectangle" x="2" y="8" width="15" height="2" rx="1"></rect>
    </g>
  </g>
</svg>
