<button
  mat-button
  [color]="color"
  class="gg-button gg-button--{{ size }} mat-{{ styleType }} {{ customClass }}"
  [ngClass]="{ 'gg-button--loading': loading }"
  [disabled]="disabled || loading"
  (click)="!disabled && !loading && clickEvent.emit($event)"
  [type]="type"
>
  <ng-content></ng-content>

  <mat-spinner class="gg-button__spinner" *ngIf="loading" [diameter]="15"></mat-spinner>
</button>
