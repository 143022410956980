import { WlcmIconName, WlcmIcons } from '@wlcm/angular/core';
import { CalendarIconComponent } from '../components/icons/calendar-icon/calendar-icon.component';
import { SearchIconComponent } from '@shared/components/icons/search-icon/search-icon.component';
import { ChevronDownIconComponent } from '@shared/components/icons';

export const WLCM_ICONS_CONFIG: WlcmIcons = {
  [WlcmIconName.SEARCH]: SearchIconComponent,
  [WlcmIconName.CALENDAR]: CalendarIconComponent,
  [WlcmIconName.CHEVRON_DOWN]: ChevronDownIconComponent,
};
