import { HttpParams } from '@angular/common/http';
import { WlcmPaginatedData } from '@wlcm/common';
import { WlcmQueryParams } from '@wlcm/common';
import { AccountInfo, User, UserIntro } from '@core/models/user.models';
import { OnDemandAccess } from '@core/models/on-demand.models';
import { GgHttpGetOptions, GgHttpParams } from '@core/models/http.models';
import { Client } from '@core/models/client.models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpApiService } from './http-api.service';

export const FEATURE: string = 'user';

@Injectable({ providedIn: 'root' })
export class UserApiService {
  constructor(private readonly httpApi: HttpApiService) {}

  get(): Observable<User> {
    return this.httpApi.get<User>(`${FEATURE}`);
  }

  getUsersByType(searchParams: WlcmQueryParams, type: 'hr' | 'broker'): Observable<WlcmPaginatedData<User>> {
    return this.httpApi.get<WlcmPaginatedData<User>>(`${FEATURE}/${type}`, { params: searchParams });
  }

  getAccountInfo(): Observable<AccountInfo> {
    return this.httpApi.get<AccountInfo>(`${FEATURE}/account-info`);
  }

  getAccountInfoById(id: string): Observable<AccountInfo> {
    return this.httpApi.get<AccountInfo>(`${FEATURE}/account-info/${id}`);
  }

  getClientsByUserId(id: string): Observable<Client[]> {
    return this.httpApi.get<Client[]>(`${FEATURE}/${id}/clients`);
  }

  getPdf(id: string): Observable<Blob> {
    return this.httpApi.get(`${FEATURE}/pdfs/${id}`, { responseType: 'blob' });
  }

  getPdfs(): Observable<OnDemandAccess> {
    return this.httpApi.get<OnDemandAccess>(`${FEATURE}/pdfs`);
  }

  getPdfThumbnail(id: string): Observable<Blob> {
    return this.httpApi.get(`${FEATURE}/pdfs/${id}-preview`, { responseType: 'blob' });
  }

  update(user: Partial<User>): Observable<User> {
    return this.httpApi.patch<User>(FEATURE, user);
  }

  validate(payload: UserIntro): Observable<boolean> {
    return this.httpApi.post<boolean>(`${FEATURE}/validate`, payload);
  }

  uploadAvatar(file: File, options?: GgHttpGetOptions): Observable<unknown> {
    const formData: FormData = new FormData();

    formData.append('image', file, file.name);

    return this.httpApi.post<unknown>(`${FEATURE}/upload-image`, formData, options);
  }
}
