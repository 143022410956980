<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/calendar</title>
  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="icons/calendar">
      <path
        d="M3,5 L15,5 L15,13 C15,14.1045695 14.1045695,15 13,15 L5,15 C3.8954305,15 3,14.1045695 3,13 L3,5 L3,5 Z"
        id="Rectangle"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <rect id="Rectangle" fill="currentColor" x="4" y="2" width="2" height="3" rx="1"></rect>
      <rect id="Rectangle" fill="currentColor" x="3" y="7" width="13" height="2" rx="1"></rect>
      <rect id="Rectangle" fill="currentColor" x="12" y="2" width="2" height="3" rx="1"></rect>
    </g>
  </g>
</svg>
