import { ApiError } from '@core/models/api-error.modes';
import { LoadArticlesPayload, LoadArticlesResponse } from '@core/models/article.models';
import { createAction, props } from '@ngrx/store';

export const loadArticles = createAction(
  '[Article API] Load Articles',
  props<{ payload?: Partial<LoadArticlesPayload> }>()
);
export const loadArticlesSuccess = createAction(
  '[Article API] Load Articles Success',
  props<{ response: LoadArticlesResponse }>()
);
export const loadArticlesFailure = createAction('[Article API] Load Articles Failure', props<{ error: ApiError }>());

export const selectOne = createAction('[Article API] Select One', props<{ payload: string }>());

export const selectOnlyOne = createAction('[Article API] Select Only One', props<{ payload: string }>());

export const deselectAll = createAction('[Article API] Deselect All');

export const resetArticles = createAction('[Article API] Reset Articles');
