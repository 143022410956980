import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import * as authActions from '../auth/auth.actions';
import * as officesActions from './offices.actions';

import { BaseEffect } from '@store/base/base.models';
import { Observable, exhaustMap, map, tap, catchError, of, switchMap } from 'rxjs';
import { CompanyApiService } from '@core/services/api/company-api.service';
import { ClientsFacade } from '@store/clients/clients.facade';
import { ClientOffice } from '@core/models/client.models';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class OfficesEffects extends BaseEffect {
  constructor(
    private readonly actions$: Actions,
    private companyApi: CompanyApiService,
    private clientsFacade: ClientsFacade
  ) {
    super();
  }

  loadOffices$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(officesActions.loadOffices),
      exhaustMap(() =>
        this.companyApi
          .getOffices()
          .pipe(this.handleResponse(officesActions.loadOfficesSuccess, officesActions.loadOfficesFailure))
      )
    )
  );

  retrieveLatestOfficeData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(officesActions.retrieveLatestOfficeData),
      exhaustMap((action) =>
        this.companyApi
          .getOffice(action.payload)
          .pipe(
            this.handleResponse(
              officesActions.retrieveLatestOfficeDataSuccess,
              officesActions.retrieveLatestOfficeDataFailure
            )
          )
      )
    )
  );

  addOffices$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(officesActions.addOffice),
      exhaustMap((action) =>
        this.companyApi
          .createOffice(action.payload)
          .pipe(tap((office: ClientOffice) => this.clientsFacade.addOfficeLocally(office.company.id, office)))
          .pipe(this.handleResponse(officesActions.addOfficeSuccess, officesActions.addOfficeFailure))
      )
    )
  );

  patchOffices$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(officesActions.patchOffice),
      exhaustMap((action) =>
        this.companyApi
          .patchOffice(action.payload)
          .pipe(tap((office: ClientOffice) => this.clientsFacade.patchOfficeLocally(office.company.id, office)))
          .pipe(this.handleResponse(officesActions.patchOfficeSuccess, officesActions.patchOfficeFailure))
      )
    )
  );

  deleteOffice$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(officesActions.deleteOffice),
      exhaustMap((action) =>
        this.companyApi
          .deleteOffice(action.payload)
          .pipe(tap(() => this.clientsFacade.deleteOfficeLocally(action.companyId, action.payload)))
          .pipe(switchMap(() => [officesActions.deleteOfficeSuccess({ response: action.payload })]))
          .pipe(catchError(({ error }: HttpErrorResponse) => of(officesActions.deleteOfficeFailure({ error }))))
      )
    )
  );

  reset$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logOutSuccess),
      map(() => officesActions.reset())
    )
  );
}
