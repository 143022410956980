import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gg-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() size: 'sm' | 'lg' = 'lg';
  @Input() styleType: 'button' | 'raised-button' = 'button';
  @Input() color: string = 'primary';

  @Input() type: string = 'button';
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input('class') customClass: string = '';

  @Output() clickEvent: EventEmitter<Event> = new EventEmitter();

  constructor() {}
}
