import { Type } from '@angular/core';
import { ConfirmationPopupComponent } from './confirmation-popup/confirmation-popup.component';
import { GenericPopupComponent } from './generic-popup/generic-popup.component';

import * as fromPopupContent from './popup-content';

export * from './popup-content';
export * from './generic-popup/generic-popup.component';
export * from './confirmation-popup/confirmation-popup.component';

export const components: Array<Type<any>> = [
  GenericPopupComponent,
  ConfirmationPopupComponent,
  ...fromPopupContent.components,
];
