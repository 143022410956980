import { ApiError } from '@core/models/api-error.modes';
import { ClassArea } from '@core/models/class-area.models';
import { createAction, props } from '@ngrx/store';

export const loadAreas = createAction('[Area API] Load Areas');
export const loadAreasSuccess = createAction('[Area API] Load Areas Success', props<{ response: ClassArea[] }>());
export const loadAreasFailure = createAction('[Area API] Load Areas Failure', props<{ error: ApiError }>());

export const select = createAction('[Area API] Select', props<{ payload: string }>());

export const reset = createAction('[Area API] Reset');
