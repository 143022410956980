import { Type } from '@angular/core';
import { ButtonComponent } from './button/button.component';
import { RemoveClassButtonComponent } from './remove-class-button/remove-class-button.component';
import { BookClassButtonComponent } from './book-class-button/book-class-button.component';

export * from './button/button.component';
export * from './remove-class-button/remove-class-button.component';
export * from './book-class-button/book-class-button.component';

export const components: Array<Type<any>> = [ButtonComponent, RemoveClassButtonComponent, BookClassButtonComponent];
