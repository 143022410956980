import { APP_INITIALIZER, Provider } from '@angular/core';
import { ScrollbarApiService } from '@core/services/helpers/scrollbar-api.service';

export const CustomScrollbar: Provider = {
  provide: APP_INITIALIZER,
  deps: [ScrollbarApiService],
  multi: true,
  useFactory: (scrollbarApi: ScrollbarApiService) => {
    return () => {
      if (navigator.userAgent.indexOf('Mac') === -1 && navigator.userAgent.indexOf('like Mac') === -1) {
        scrollbarApi.customize();
      }
    };
  },
};
