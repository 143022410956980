import { InjectionToken } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { GgPopupContentConfig } from '@core/models/popup.models';

const DIALOG_CONFIG: MatDialogConfig = {
  autoFocus: false,
  hasBackdrop: true,
  panelClass: ['gg-dialog-panel'],
  backdropClass: 'gg-dialog-backdrop',
  minWidth: '350px',
  maxHeight: '80vh',
};

export const DEFAULT_DIALOG_CONFIG: InjectionToken<MatDialogConfig> = new InjectionToken('DEFAULT_DIALOG_CONFIG', {
  providedIn: 'root',
  factory: () => DIALOG_CONFIG,
});

export const DEFAULT_POPUP_CONTENT_CONFIG: GgPopupContentConfig = {
  title: 'Popup Title',
  hasCloseButton: true,
};
