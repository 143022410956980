import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ScrollbarApiService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  customize(): void {
    const head: HTMLHeadElement = this.document.getElementsByTagName('head')[0];

    const style: HTMLLinkElement = this.document.createElement('link');
    style.href = `custom-scrollbar-theme.css`;
    style.rel = 'stylesheet';

    head.appendChild(style);
  }
}
