import { ClassSearchParams, ClassType } from '@core/models/class.models';
import { GgSelectOption } from '@core/models/option.models';

export const DEFAULT_CLASSES_SEARCH_PARAMS: ClassSearchParams = {
  page: 1,
  limit: 9,
  query: '',
  area: [],
  class_type: [],
};

export const CLASS_TYPE_OPTIONS: GgSelectOption[] = [
  new GgSelectOption(ClassType.ONSITE, 'On-Site'),
  new GgSelectOption(ClassType.VIRTUAL, 'Virtual'),
];
