import { Injectable } from '@angular/core';
import {
  Invite,
  InvitePayload,
  InviteRegisteredUserPayload,
  LoadInvitationsParams,
  LoadInvitationsResponse,
  ResendInvitePayload,
} from '@core/models/invite.models';
import { SignInResponse } from '@core/models/sign-in.models';
import { HttpApiService } from './http-api.service';
import { Observable } from 'rxjs';

const FEATURE: string = 'invite';

@Injectable({ providedIn: 'root' })
export class InviteApiService {
  constructor(private readonly httpApi: HttpApiService) {}

  get(params: LoadInvitationsParams): Observable<LoadInvitationsResponse> {
    return this.httpApi.get<LoadInvitationsResponse>(`${FEATURE}/${params.state}`, { params });
  }

  getOne(id: string): Observable<Invite> {
    return this.httpApi.get<Invite>(`${FEATURE}/${id}`);
  }

  getByCompany(id: string): Observable<Invite> {
    return this.httpApi.get<Invite>(`${FEATURE}/company/${id}`);
  }

  create(invite: InvitePayload): Observable<Invite> {
    return this.httpApi.post<Invite>(FEATURE, invite);
  }

  sendToRegisteredUser(id: string, payload: InviteRegisteredUserPayload): Observable<Invite> {
    return this.httpApi.post<Invite>(`${FEATURE}/${id}`, payload);
  }

  joinCompany(id: string): Observable<Invite> {
    return this.httpApi.post<Invite>(`${FEATURE}/join-company/${id}`, {});
  }

  accept(id: string): Observable<Invite> {
    return this.httpApi.post<Invite>(`${FEATURE}/accept/${id}`, {});
  }

  resend(id: string, payload: ResendInvitePayload = {}): Observable<Invite> {
    return this.httpApi.post<Invite>(`${FEATURE}/resend/${id}`, payload);
  }

  decline(id: string): Observable<Invite> {
    return this.httpApi.post<Invite>(`${FEATURE}/decline/${id}`, {});
  }

  cancel(id: string): Observable<Invite> {
    return this.httpApi.post<Invite>(`${FEATURE}/cancel/${id}`, {});
  }

  authenticate(id: string): Observable<SignInResponse> {
    return this.httpApi.get<SignInResponse>(`${FEATURE}/auth/${id}`);
  }
}
