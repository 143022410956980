import { HttpHeaders } from '@angular/common/http';

export class GgHttpUtils {
  static skipSuccessMessageHeader: string = 'skip_success_message';

  static skipSuccessMessage(headers: HttpHeaders = new HttpHeaders()): HttpHeaders {
    return headers.set(this.skipSuccessMessageHeader, 'true');
  }

  static transformToFormData(payload: object): FormData {
    let formData: FormData = new FormData();

    for (let key in payload) {
      let value: string | Blob = payload[key];

      const isBlob = value instanceof Blob;

      if (typeof value === 'object' && value !== null && !isBlob) {
        if (Array.isArray(value)) {
          for (let aKey in value as unknown[]) {
            formData.append(`${key}[]`, value[aKey]);
          }
        } else {
          formData.append(key, JSON.stringify(value));
        }
      } else {
        formData.append(key, value);
      }
    }

    return formData;
  }
}
