import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { AuthState } from '.';
import { SignUpPayload, SignUpResponse } from '@core/models/sign-up.models';
import { StoreActionResponseApiService } from '@core/services/api/store-action-response-api.service';
import { RefreshTokenResponse } from '@core/models/refresh-token.models';
import { SignInPayload, SignInResponse } from '@core/models/sign-in.models';
import { Observable } from 'rxjs';

import * as authActions from './auth.actions';

import { UserType } from '@core/models/user.models';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({ providedIn: 'root' })
export class AuthFacade {
  readonly logOutSuccess$: Observable<void> = this.actions.pipe(ofType(authActions.logOutSuccess));

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<AuthState>,
    private readonly storeActionResponseApi: StoreActionResponseApiService
  ) {}

  signUp<T extends UserType>(payload: SignUpPayload<T>): Observable<SignUpResponse> {
    this.store.dispatch(authActions.signUp({ payload }));
    return this.storeActionResponseApi.actionResponse<SignUpResponse>(
      authActions.signUpSuccess,
      authActions.signUpFailure
    );
  }

  logIn(payload: SignInPayload): Observable<SignInResponse> {
    this.store.dispatch(authActions.signIn({ payload }));
    return this.storeActionResponseApi.actionResponse<SignUpResponse>(
      authActions.signInSuccess,
      authActions.signInFailure
    );
  }

  authenticate(payload: string): Observable<SignInResponse> {
    this.store.dispatch(authActions.authenticate({ payload }));
    return this.storeActionResponseApi.actionResponse<SignInResponse>(
      authActions.authenticateSuccess,
      authActions.authenticateFailure
    );
  }

  refreshToken(): Observable<RefreshTokenResponse> {
    this.store.dispatch(authActions.refreshToken());
    return this.storeActionResponseApi.actionResponse<RefreshTokenResponse>(
      authActions.refreshTokenSuccess,
      authActions.refreshTokenFailure
    );
  }

  logOut(withRedirect: boolean = true): Observable<Action> {
    this.store.dispatch(authActions.logOut({ withRedirect }));
    return this.actions.pipe(ofType(authActions.logOutSuccess));
  }
}
