import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GgHttpBaseOptions, GgHttpGetOptions, GgHttpHeaders } from '@core/models/http.models';
import { GgHttpUtils } from '@core/utils/http.utils';
import { Observable } from 'rxjs';

type HttpOptions = unknown;

@Injectable({ providedIn: 'root' })
export class HttpApiService {
  constructor(private readonly http: HttpClient) {}

  get<T>(url: string, options?: GgHttpGetOptions): Observable<T> {
    return this.http.get<T>(url, this.handleOptions(options));
  }

  post<T>(url: string, body: any | null, options?: GgHttpGetOptions): Observable<T> {
    return this.http.post<T>(url, body, this.handleOptions(options));
  }

  patch<T>(url: string, body: any | null, options?: GgHttpGetOptions): Observable<T> {
    return this.http.patch<T>(url, body, this.handleOptions(options));
  }

  delete<T>(url: string, options?: GgHttpGetOptions & { body: unknown }): Observable<T> {
    return this.http.delete<T>(url, this.handleOptions(options));
  }

  private handleOptions(options: GgHttpBaseOptions): HttpOptions {
    if (!options) return {};

    let headers: HttpHeaders = this.transformHeaders(options.headers);

    if (options.skipSuccessMessage) {
      headers = GgHttpUtils.skipSuccessMessage(headers);
    }

    return { ...options, headers };
  }

  private transformHeaders(headers: GgHttpHeaders): HttpHeaders {
    let _headers: HttpHeaders = new HttpHeaders();

    if (headers instanceof Object) {
      for (const key in headers) {
        _headers = _headers.set(key, headers[key]);
      }
    }

    return _headers;
  }
}
