import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import * as fromActions from './subscription.actions';

import { SubscriptionState } from '.';

export const SUBSCRIPTION_FEATURE_KEY = 'subscription';

export const initialState: SubscriptionState = {
  prices: null,
  subscription: null,
};

export const subscriptionReducer: ActionReducer<SubscriptionState, Action> = createReducer(
  initialState,

  on(fromActions.loadSubscriptionSuccess, (state, action) => ({ ...state, subscription: action.response })),

  on(fromActions.loadPricesSuccess, (state, action) => ({ ...state, prices: action.response }))
);
