<div class="popup-header" *ngIf="!customContent; else customContentTpl">
  <div class="popup-header__title xl-title-text">
    {{ config.title }}
  </div>

  <gg-close-icon class="cursor-pointer" *ngIf="config.hasCloseButton" (click)="close.emit()"></gg-close-icon>
</div>

<ng-template #customContentTpl>
  <ng-content select="[ggCustomPopupContent]"></ng-content>
</ng-template>
