<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/menu/contact</title>
  <g id="icons/menu/contact" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path
      d="M1,8.28573479 L1,2.0714337 C1,1.49942308 1.44771525,1.03571685 2,1.03571685 L13,1.03571685 C13.5522847,1.03571685 14,1.49942308 14,2.0714337 L14,3.1381266"
      id="Path"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    ></path>
    <path
      d="M5,6 L16,6 C16.5522847,6 17,6.46370623 17,7.03571685 L17,13.2500179 C17,13.8220286 16.5522847,14.2857348 16,14.2857348 L10.9827638,14.2857348 L8.79236391,16.58554 C8.43439201,17.0211258 7.80326349,17.0736795 7.38269891,16.702922 C7.15947946,16.5061382 7.03086437,16.2178253 7.03086437,15.9142241 L7.03086437,14.2857348 L5,14.2857348 C4.44771525,14.2857348 4,13.8220286 4,13.2500179 L4,7.03571685 C4,6.46370623 4.44771525,6 5,6 Z"
      id="Rectangle"
      stroke="currentColor"
      stroke-width="2"
    ></path>
  </g>
</svg>
