<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="icons/menu/Employees" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <circle id="Oval" stroke="#FFFFFF" stroke-width="2" cx="7" cy="5" r="4"></circle>
    <circle id="Oval" stroke="#FFFFFF" stroke-width="2" cx="13.5" cy="8.5" r="2.5"></circle>
    <path
      d="M7,9 C10.3137085,9 13,11.6862915 13,15 L13,17 L13,17 L1,17 L1,15 C1,11.6862915 3.6862915,9 7,9 Z"
      id="Rectangle"
      stroke="#FFFFFF"
      stroke-width="2"
    ></path>
    <path
      d="M11.9159947,11.2442147 C12.3304631,11.0864134 12.7801401,11 13.25,11 C15.3210678,11 17,12.6789322 17,14.75 L17,17 L9.5,17"
      id="Path"
      stroke="#FFFFFF"
      stroke-width="2"
    ></path>
  </g>
</svg>
