import { ApiError } from '@core/models/api-error.modes';
import { Subscription, SubscriptionPrices } from '@core/models/subscription.models';
import { createAction, props } from '@ngrx/store';

export const loadAll = createAction('[Subscription API] Load All');

export const loadSubscription = createAction('[Subscription API] Load Subscription');
export const loadSubscriptionSuccess = createAction(
  '[Subscription API] Load Subscription Success',
  props<{ response: Subscription }>()
);
export const loadSubscriptionFailure = createAction(
  '[Subscription API] Load Subscription Failure',
  props<{ error: ApiError }>()
);

export const loadPrices = createAction('[Subscription API] Load Prices');
export const loadPricesSuccess = createAction(
  '[Subscription API] Load Prices Success',
  props<{ response: SubscriptionPrices }>()
);
export const loadPricesFailure = createAction('[Subscription API] Load Prices Failure', props<{ error: ApiError }>());
