import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthFacade } from '@store/auth/auth.facade';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { AuthApiService } from '../api/auth-api.service';

@Injectable()
export class ApiTokenInterceptor implements HttpInterceptor {
  constructor(private authApi: AuthApiService, private authFacade: AuthFacade) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(this.addAccessToken(request))
      .pipe(catchError((error: HttpErrorResponse) => this.handleApiError(error, request, next)));
  }

  private handleApiError(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (error.status === HttpStatusCode.Unauthorized) {
      if (this.authApi.getRefreshToken()) {
        return this.refreshToken(request, next);
      }

      this.authFacade.logOut();
    }

    return throwError(() => error);
  }

  private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authFacade.refreshToken().pipe(
      switchMap(({ accessToken }) => {
        const transformedRequest: HttpRequest<any> = this.addAccessToken(request, accessToken);
        return next.handle(transformedRequest);
      }),
      catchError((error) => (this.authFacade.logOut(), throwError(() => error)))
    );
  }

  private addAccessToken(request: HttpRequest<any>, token?: string): HttpRequest<any> {
    const accessToken: string | null = token || this.authApi.getAccessToken();
    return request.clone({ setHeaders: { Authorization: `Bearer ${accessToken}` } });
  }
}
