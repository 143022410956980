import { Component, OnInit, ChangeDetectionStrategy, Input, ContentChild, Output, EventEmitter } from '@angular/core';
import { DEFAULT_POPUP_CONTENT_CONFIG } from '@core/constants/dialog.constants';
import { GgPopupContentConfig } from '@core/models/popup.models';
import { CustomPopupContentDirective } from '@shared/directives/popups/custom-popup-content.directive';

@Component({
  selector: 'gg-popup-footer',
  templateUrl: './popup-footer.component.html',
  styleUrls: ['./popup-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupFooterComponent implements OnInit {
  @Input() config: GgPopupContentConfig = DEFAULT_POPUP_CONTENT_CONFIG;

  @Input() disabled: boolean = false;

  @Input() isLoading: boolean = false;

  @Output() primaryButtonClick: EventEmitter<Event> = new EventEmitter();

  @Output() secondaryButtonClick: EventEmitter<Event> = new EventEmitter();

  @ContentChild(CustomPopupContentDirective) customContent: CustomPopupContentDirective;

  constructor() {}

  ngOnInit(): void {}
}
