import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { StoreActionResponseApiService } from '@core/services/api/store-action-response-api.service';
import { combineLatest, filter, map, Observable, take } from 'rxjs';

import * as fromActions from './clients.actions';
import * as fromSelectors from './clients.selectors';

import { ClientsState } from './clients.state';
import { Client, ClientOffice, ClientPayload } from '@core/models/client.models';
import { Dictionary } from '@ngrx/entity';
import { GgStoreUtils } from '@core/utils/store.utils';

@Injectable({ providedIn: 'root' })
export class ClientsFacade {
  readonly isLoaded$: Observable<boolean> = this.store.select(fromSelectors.selectIsLoaded);
  readonly isLoading$: Observable<boolean> = this.store.select(fromSelectors.selectIsLoading);

  readonly isStatePristine$: Observable<boolean> = combineLatest([
    this.isLoaded$.pipe(take(1)),
    this.isLoading$.pipe(take(1)),
  ]).pipe(map(([isLoaded, isLoading]) => !isLoaded && !isLoading));

  readonly clients$: Observable<Client[]> = this.store
    .select(fromSelectors.selectAll)
    .pipe(GgStoreUtils.makeConfigurable());

  readonly clientsEntities$: Observable<Dictionary<Client>> = this.store.select(fromSelectors.selectEntities);

  readonly selected$: Observable<Client> = this.store.select(fromSelectors.selectCurrentClient).pipe(filter(Boolean));

  constructor(
    private readonly store: Store<ClientsState>,
    private readonly storeActionResponseApi: StoreActionResponseApiService
  ) {}

  loadAll(): Observable<Client[]> {
    this.store.dispatch(fromActions.loadClients());
    return this.storeActionResponseApi.actionResponse<Client[]>(
      fromActions.loadClientsSuccess,
      fromActions.loadClientsFailure
    );
  }

  pullOne(companyId: string): Observable<Client> {
    this.store.dispatch(fromActions.pullOne({ payload: companyId }));
    return this.storeActionResponseApi.actionResponse<Client>(fromActions.pullOneSuccess, fromActions.pullOneFailure);
  }

  create(payload: ClientPayload): Observable<Client> {
    this.store.dispatch(fromActions.addClient({ payload }));
    return this.storeActionResponseApi.actionResponse<Client>(
      fromActions.addClientSuccess,
      fromActions.addClientFailure
    );
  }

  patch(payload: Partial<ClientPayload>): Observable<Client> {
    this.store.dispatch(fromActions.patchClient({ payload }));
    return this.storeActionResponseApi.actionResponse<Client>(
      fromActions.patchClientSuccess,
      fromActions.patchClientFailure
    );
  }

  patchLocally(payload: Partial<Client>): void {
    this.store.dispatch(fromActions.patchClientLocally({ payload }));
  }

  addOfficeLocally(clientId: string, payload: ClientOffice): void {
    this.store.dispatch(fromActions.addOfficeLocally({ clientId, payload }));
  }

  patchOfficeLocally(clientId: string, payload: Partial<ClientOffice>): void {
    this.store.dispatch(fromActions.patchOfficeLocally({ clientId, payload }));
  }

  deleteOfficeLocally(clientId: string, payload: string): void {
    this.store.dispatch(fromActions.deleteOfficeLocally({ clientId, payload }));
  }

  delete(payload: string): Observable<string> {
    this.store.dispatch(fromActions.deleteClient({ payload }));
    return this.storeActionResponseApi.actionResponse<string>(
      fromActions.deleteClientSuccess,
      fromActions.deleteClientFailure
    );
  }

  select(payload: string): void {
    this.store.dispatch(fromActions.selectClient({ payload }));
  }

  reset(): void {
    this.store.dispatch(fromActions.reset());
  }
}
