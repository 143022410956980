<svg
  width="42px"
  height="24px"
  viewBox="0 0 42 24"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>logo white</title>
  <g id="logo-white" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Group-6" fill="currentColor" fill-rule="nonzero">
      <path
        d="M12.5100931,14.5086149 L17.6652723,14.4578183 C17.6433936,14.5099878 17.6215148,14.5621573 17.5982687,14.6143267 C16.4114768,17.1275653 13.7454838,18.5853121 10.9987769,18.2228837 C8.25207003,17.8604554 6.05177714,15.7606016 5.55209537,13.0248326 C5.05241361,10.2890635 6.36743029,7.54200994 8.8073334,6.22468052 C11.2472365,4.9073511 14.2549475,5.32051844 16.2527259,7.24744932 C16.2527259,7.24744932 17.9100408,3.88114604 19.1010649,2.51238406 C15.0226171,-0.682703831 9.34905498,-0.845402281 5.09561042,2.11075529 C0.842165855,5.06691287 -1.00374188,10.4556669 0.538900493,15.4131477 C2.08154287,20.3706285 6.65461742,23.7459777 11.8277498,23.7453602 C14.3254138,23.7475281 16.7589003,22.9512396 18.7756186,21.4718654 C19.7243253,20.7775038 20.5664982,19.9472328 21.2752652,19.0075447 C22.1395012,17.8498208 22.7880684,16.5447171 23.1896553,15.1552417 C23.2252083,15.0097163 23.266231,14.8683096 23.3017839,14.7282758 C23.318193,14.6623776 23.3304998,14.5909878 23.344174,14.5292081 L14.3834606,10.7963457 L12.5100931,14.5086149 Z"
        id="Path"
      ></path>
      <path
        d="M38.40222,3.74111221 C34.2957016,-0.376495986 27.8211232,-0.89687896 23.1144472,2.51238406 C22.6053395,2.88259269 22.1253281,3.29154279 21.6786545,3.73562069 C20.3311706,5.08727323 19.3295125,6.7462538 18.7592095,8.57090624 C18.7008662,8.76219427 18.6461694,8.95302468 18.595119,9.14339746 C18.5664031,9.25734655 18.5431569,9.37129564 18.5185434,9.48661761 L27.4751545,13.2181071 L29.6288434,9.5442786 L24.197445,9.5442786 C24.2562441,9.40699053 24.3191455,9.26970247 24.3875166,9.14339746 C24.6875637,8.57527077 25.069037,8.05443278 25.5197417,7.59753388 C28.0104013,5.09429292 32.0494627,5.08817699 34.5476316,7.58386389 C37.0458004,10.0795508 37.0598164,14.1347173 34.5789593,16.6477511 C32.0981022,19.1607848 28.0590958,19.1828127 25.5511923,16.6969866 C24.9359981,18.4581078 23.9889241,20.0838833 22.7616524,21.4855942 C27.6857249,25.3457193 34.7512318,24.6933009 38.8914138,19.996196 C43.0315959,15.2990912 42.8173086,8.17868787 38.40222,3.74111221 Z"
        id="Path"
      ></path>
    </g>
  </g>
</svg>
