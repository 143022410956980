import { ApiError } from '@core/models/api-error.modes';
import { Recommendation } from '@core/models/recommendation.models';
import { createAction, props } from '@ngrx/store';

export const loadCurrentRecommendation = createAction(
  '[Current Recommendation API] Load Current Recommendation',
  props<{ payload: string }>()
);
export const loadCurrentRecommendationSuccess = createAction(
  '[Current Recommendation API] Load Current Recommendation Success',
  props<{ response: Recommendation }>()
);
export const loadCurrentRecommendationFailure = createAction(
  '[Current Recommendation API] Load Current Recommendation Failure',
  props<{ error: ApiError }>()
);
