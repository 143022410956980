import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';

import * as fromActions from './current-recommendation.actions';

import { BaseEffect } from '@store/base/base.models';
import { CurrentRecommendationState } from './current-recommendation.state';
import { RecommendationApiService } from '@core/services/api/recommendation-api.service';

@Injectable()
export class CurrentRecommendationEffects extends BaseEffect<CurrentRecommendationState> {
  constructor(
    private readonly actions$: Actions,
    private readonly recommendationApi: RecommendationApiService,
    public readonly store: Store<CurrentRecommendationState>
  ) {
    super(store);
  }

  loadCurrentRecommendation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadCurrentRecommendation),
      exhaustMap((action) =>
        this.recommendationApi
          .getCurrent(action.payload)
          .pipe(
            this.handleResponse(
              fromActions.loadCurrentRecommendationSuccess,
              fromActions.loadCurrentRecommendationFailure
            )
          )
      )
    )
  );
}
