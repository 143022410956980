<gg-generic-popup class="booking-not-allowed-popup">
  <gg-popup-content>
    <div class="booking-not-allowed-popup__content xs-content-text" ggCustomPopupContent>
      To book this event please contact <a href="mailto: info@goomigroup.com">info&#64;goomigroup.com</a>
    </div>
  </gg-popup-content>

  <gg-popup-footer>
    <div class="booking-not-allowed-popup__actions" ggCustomPopupContent>
      <gg-button styleType="raised-button" ggCopyToClipboard="info@goomigroup.com">Copy email</gg-button>
    </div>
  </gg-popup-footer>
</gg-generic-popup>
