import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClassesState, CLASSES_FEATURE_KEY, selectAllClasses } from '.';
import { selectClassesEntities } from './classes.reducer';

const selectClassesState = createFeatureSelector<ClassesState>(CLASSES_FEATURE_KEY);

const getSelected = (state: ClassesState) => state.selected;
const getSearchParams = (state: ClassesState) => state.searchParams;
const getPaginatedData = (state: ClassesState) => state.paginatedData;

export const selectAll = createSelector(selectClassesState, selectAllClasses);
export const selectSelected = createSelector(selectClassesState, getSelected);
export const selectEntities = createSelector(selectClassesState, selectClassesEntities);
export const selectSearchParams = createSelector(selectClassesState, getSearchParams);
export const selectPaginatedData = createSelector(selectClassesState, getPaginatedData);

export const selectIsLoaded = createSelector(selectClassesState, (state: ClassesState) => state.isLoaded);
export const selectIsLoading = createSelector(selectClassesState, (state: ClassesState) => state.isLoading);
