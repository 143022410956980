import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'gg-delete-icon',
  templateUrl: './delete-icon.component.html',
  styleUrls: ['./delete-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
