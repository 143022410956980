import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SnackbarApiService {
  constructor(private snackBar: MatSnackBar) {}

  failure(message: string = 'Failure!', duration: number = 5000): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, null, { duration, panelClass: 'failure' });
  }

  success(message: string = 'Success!', duration: number = 3000): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, null, { duration, panelClass: 'success' });
  }

  successOperatorFn = <T>(message: string = 'Success!', duration: number = 3000) => {
    return (source: Observable<T>) => source.pipe(tap(() => this.success(message, duration)));
  };
}
