import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { APP_VERSION } from '@core/constants/platform/app-version.token';
import { ENVIRONMENT } from '@core/constants/platform/environment.token';

const { version: appVersion } = require('../package.json');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic([
  { provide: APP_VERSION, useValue: appVersion },
  { provide: ENVIRONMENT, useValue: environment },
])
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
