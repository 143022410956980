<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/menu/activity calendar</title>
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="UI" transform="translate(-551.000000, -827.000000)">
      <g id="Group" transform="translate(551.000000, 827.000000)">
        <g id="icons/calendar" transform="translate(1.000000, 0.000000)">
          <path
            d="M0,3 L16,3 L16,16 C16,16.5522847 15.5522847,17 15,17 L1,17 C0.44771525,17 6.76353751e-17,16.5522847 0,16 L0,3 L0,3 Z"
            id="Rectangle"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <rect id="Rectangle" fill="currentColor" x="2" y="0" width="2" height="4" rx="1"></rect>
          <rect id="Rectangle" fill="currentColor" x="12" y="0" width="2" height="4" rx="1"></rect>
          <polyline
            id="Path"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="4 10.4063963 8.57119713 6.98846161 8.57119713 13.0457269 12.0028363 10.4063963"
          ></polyline>
        </g>
      </g>
    </g>
  </g>
</svg>
