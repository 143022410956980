import { HttpClient, HttpParams } from '@angular/common/http';
import { ClientOffice, ClientOfficePayload } from '@core/models/client.models';
import { AddCompanyOfficePayload, Company } from '@core/models/company.models';
import { WlcmPaginatedData } from '@wlcm/common';
import { WlcmQueryParams } from '@wlcm/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const FEATURE: string = 'company';

@Injectable({ providedIn: 'root' })
export class CompanyApiService {
  constructor(private readonly http: HttpClient) {}

  get(searchParams: WlcmQueryParams): Observable<WlcmPaginatedData<Company>> {
    const params: HttpParams = new HttpParams().appendAll(searchParams as any);

    return this.http.get<WlcmPaginatedData<Company>>(`${FEATURE}`, { params });
  }

  getOffice(id: string): Observable<ClientOffice> {
    return this.http.get<ClientOffice>(`${FEATURE}/office/${id}`);
  }

  getOffices(): Observable<ClientOffice[]> {
    return this.http.get<ClientOffice[]>(`${FEATURE}/office`);
  }

  createOffice(payload: AddCompanyOfficePayload): Observable<ClientOffice> {
    return this.http.post<ClientOffice>(`${FEATURE}/office`, payload);
  }

  patchOffice(payload: Partial<ClientOfficePayload>): Observable<ClientOffice> {
    return this.http.patch<ClientOffice>(`${FEATURE}/office`, payload);
  }

  deleteOffice(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${FEATURE}/office/${id}`);
  }

  acceptRequest(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${FEATURE}/accept-request/${id}`);
  }
}
