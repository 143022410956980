import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@core/constants/token.constants';

export type AuthState = {
  [key in typeof ACCESS_TOKEN_KEY | typeof REFRESH_TOKEN_KEY]: string | null;
};

export const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
};
