import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { CurrentRecommendationState } from './current-recommendation.state';

import * as fromActions from './current-recommendation.actions';

export const CURRENT_RECOMMENDATION_FEATURE_KEY = 'current-recommendation';

export const initialState: CurrentRecommendationState = {
  data: null,
  isLoading: false,
  isLoaded: false,
};

export const currentRecommendationReducer: ActionReducer<CurrentRecommendationState, Action> = createReducer(
  initialState,

  on(fromActions.loadCurrentRecommendation, (state) => ({ ...state, isLoading: true })),

  on(fromActions.loadCurrentRecommendationSuccess, (state, action) => {
    return { ...state, isLoaded: true, isLoading: false, data: action.response };
  }),

  on(fromActions.loadCurrentRecommendationFailure, (state) => ({ ...state, isLoading: false }))
);
