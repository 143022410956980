import { Class } from '@core/models/class.models';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { ClassesState } from './classes.state';

import * as fromActions from './classes.actions';

import { DEFAULT_CLASSES_SEARCH_PARAMS } from '@core/constants/class.constants';

export const CLASSES_FEATURE_KEY = 'classes';

const adapter: EntityAdapter<Class> = createEntityAdapter<Class>();

export const initialState: ClassesState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  searchParams: { ...DEFAULT_CLASSES_SEARCH_PARAMS },
  paginatedData: null,
  selected: [],
});

export const classesReducer: ActionReducer<ClassesState, Action> = createReducer(
  initialState,

  on(fromActions.loadClasses, (state, action) => {
    return { ...state, searchParams: { ...state.searchParams, ...action.payload }, isLoading: true, isLoaded: false };
  }),

  on(fromActions.loadClassesSuccess, (state, action) => {
    return adapter.setAll(action.response.data, {
      ...state,
      isLoaded: true,
      isLoading: false,
      paginatedData: action.response,
    });
  }),

  on(fromActions.loadClassesFailure, (state) => ({ ...state, isLoaded: false, isLoading: false })),

  on(fromActions.loadMoreClasses, (state) => ({ ...state, isLoading: true, isLoaded: false })),

  on(fromActions.loadMoreClassesSuccess, (state, action) => {
    return adapter.addMany(action.response.data, {
      ...state,
      isLoaded: true,
      isLoading: false,
      paginatedData: action.response,
    });
  }),

  on(fromActions.loadMoreClassesFailure, (state) => ({ ...state, isLoaded: false, isLoading: false })),

  on(fromActions.createClassSuccess, (state, action) => {
    const classes: Array<Class> = Object.values(state.entities);
    let statePayload: ClassesState = JSON.parse(JSON.stringify(state));

    if (classes.length >= state.searchParams.limit) {
      classes.pop();
      statePayload.paginatedData.totalItems++;
    }

    return adapter.setAll([action.response, ...classes], statePayload);
  }),

  on(fromActions.patchClassSuccess, (state, action) => {
    return adapter.updateOne({ id: action.response.id, changes: action.response }, state);
  }),

  on(fromActions.selectOne, (state, action) => ({ ...state, selected: [...state.selected, action.payload] })),

  on(fromActions.deselectOne, (state, action) => {
    return { ...state, selected: state.selected.filter((id: string) => id !== action.payload) };
  }),

  on(fromActions.deselectAll, (state) => ({ ...state, selected: [] })),

  on(fromActions.resetClassesState, () => adapter.removeAll({ ...initialState }))
);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectClassesIds = selectIds;
export const selectClassesEntities = selectEntities;
export const selectAllClasses = selectAll;
export const selectClassesTotal = selectTotal;
