import { ClassArea } from '@core/models/class-area.models';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ActionReducer, Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './area.actions';

import { AreaState } from './area.state';

export const AREAS_FEATURE_KEY = 'areas';

const adapter: EntityAdapter<ClassArea> = createEntityAdapter<ClassArea>();

export const initialState: AreaState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  selected: null,
});

export const areaReducer: ActionReducer<AreaState, Action> = createReducer(
  initialState,

  on(fromActions.loadAreas, (state) => ({ ...state, isLoading: true })),

  on(fromActions.loadAreasSuccess, (state, action) =>
    adapter.setAll(action.response, { ...state, isLoading: false, isLoaded: true })
  ),

  on(fromActions.loadAreasFailure, (state) => ({ ...state, isLoading: false })),

  on(fromActions.select, (state, action) => ({ ...state, selected: action.payload })),

  on(fromActions.reset, () => initialState)
);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAreasIds = selectIds;
export const selectAreasEntities = selectEntities;
export const selectAllAreas = selectAll;
export const selectAreasTotal = selectTotal;
