import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { OfficesState } from './offices.state';

import * as officesActions from './offices.actions';

import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { ClientOffice } from '@core/models/client.models';

export const OFFICES_FEATURE_KEY = 'offices';

const adapter: EntityAdapter<ClientOffice> = createEntityAdapter<ClientOffice>();

export const initialState: OfficesState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  selected: null,
});

export const officesReducer: ActionReducer<OfficesState, Action> = createReducer(
  initialState,

  on(officesActions.loadOffices, (state) => ({ ...state, isLoading: true })),

  on(officesActions.loadOfficesSuccess, (state, action) =>
    adapter.setAll(action.response, { ...state, isLoaded: true, isLoading: false })
  ),

  on(officesActions.retrieveLatestOfficeDataSuccess, (state, { response: office }) =>
    adapter.updateOne({ id: office.id, changes: office }, state)
  ),

  on(officesActions.addOfficeSuccess, (state, action) => adapter.addOne(action.response, state)),

  on(officesActions.patchOfficeSuccess, (state, action) => adapter.upsertOne(action.response, state)),

  on(officesActions.deleteOfficeSuccess, (state, action) => adapter.removeOne(action.response, state)),

  on(officesActions.selectOffice, (state, action) => ({ ...state, selected: action.payload })),

  on(officesActions.reset, () => initialState)
);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectOfficesIds = selectIds;
export const selectOfficesEntities = selectEntities;
export const selectAllOffices = selectAll;
export const selectOfficesTotal = selectTotal;
