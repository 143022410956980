<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/search</title>
  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="icons/search" stroke="#000000" stroke-width="2">
      <g>
        <circle id="Oval" cx="7" cy="7" r="5"></circle>
        <line x1="10.035286" y1="11.0405606" x2="15.0375085" y2="16.0427831" id="Path-8" stroke-linecap="round"></line>
      </g>
    </g>
  </g>
</svg>
