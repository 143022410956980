import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'gg-arrow-back-icon',
  templateUrl: './arrow-back-icon.component.html',
  styleUrls: ['./arrow-back-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrowBackIconComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
