<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="icons/information">
      <circle
        id="Oval"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        cx="9"
        cy="9"
        r="6"
      ></circle>
      <circle id="Oval" fill="currentColor" cx="9" cy="6" r="1"></circle>
      <rect id="Rectangle" fill="currentColor" x="8" y="8" width="2" height="5" rx="1"></rect>
    </g>
  </g>
</svg>
