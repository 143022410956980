import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import * as authActions from '../auth/auth.actions';
import * as clientsActions from './clients.actions';

import { BaseEffect } from '@store/base/base.models';
import { ClientApiService } from '@core/services/api/client-api.service';
import { Observable, exhaustMap, of, switchMap, catchError, map } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ClientsEffects extends BaseEffect {
  constructor(private readonly actions$: Actions, private clientApi: ClientApiService) {
    super();
  }

  loadClients$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(clientsActions.loadClients),
      exhaustMap(() =>
        this.clientApi
          .get()
          .pipe(this.handleResponse(clientsActions.loadClientsSuccess, clientsActions.loadClientsFailure))
      )
    )
  );

  pullOne$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(clientsActions.pullOne),
      exhaustMap((action) =>
        this.clientApi
          .getByOwner(action.payload)
          .pipe(this.handleResponse(clientsActions.pullOneSuccess, clientsActions.pullOneFailure))
      )
    )
  );

  addClients$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(clientsActions.addClient),
      exhaustMap((action) =>
        this.clientApi
          .create(action.payload)
          .pipe(this.handleResponse(clientsActions.addClientSuccess, clientsActions.addClientFailure))
      )
    )
  );

  patchClients$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(clientsActions.patchClient),
      exhaustMap((action) =>
        this.clientApi
          .patch(action.payload)
          .pipe(this.handleResponse(clientsActions.patchClientSuccess, clientsActions.patchClientFailure))
      )
    )
  );

  deleteClients$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(clientsActions.deleteClient),
      exhaustMap((action) =>
        this.clientApi
          .delete(action.payload)
          .pipe(switchMap(() => [clientsActions.deleteClientSuccess({ response: action.payload })]))
          .pipe(catchError(({ error }: HttpErrorResponse) => of(clientsActions.deleteClientFailure({ error }))))
      )
    )
  );

  reset$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logOutSuccess),
      map(() => clientsActions.reset())
    )
  );
}
