import { MatDialogConfig } from '@angular/material/dialog';
import { GgPopupContentConfig } from '@core/models/popup.models';

export const DEFAULT_CONFIRMATION_POPUP_CONFIG: MatDialogConfig<GgPopupContentConfig> = {
  width: '90%',
  maxWidth: '470px',
  data: {
    hasCloseButton: true,
    primaryButtonText: 'Confirm',
    secondaryButtonText: 'Cancel',
  },
};
