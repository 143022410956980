import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DEFAULT_POPUP_CONTENT_CONFIG } from '@core/constants/dialog.constants';
import { GgConfirmationPopupConfig, GgPopupConfig, GgPopupContentConfig } from '@core/models/popup.models';
import { GenericPopupComponent } from '@shared/components/popups';
import { ConfirmationPopupComponent } from '@shared/components/popups/confirmation-popup/confirmation-popup.component';
import { DEFAULT_CONFIRMATION_POPUP_CONFIG } from '@shared/constants/popups/popup.constants';

@Injectable({ providedIn: 'root' })
export class PopupApiService {
  constructor(private dialog: MatDialog) {}

  openGenericPopup(config: MatDialogConfig<GgPopupContentConfig> = {}): MatDialogRef<GenericPopupComponent> {
    const data: GgPopupContentConfig = { ...DEFAULT_POPUP_CONTENT_CONFIG, ...(config.data || {}) };
    return this.dialog.open(GenericPopupComponent, { ...config, data });
  }

  openConfirmationPopup(config: GgConfirmationPopupConfig) {
    return this.dialog.open(ConfirmationPopupComponent, {
      ...DEFAULT_CONFIRMATION_POPUP_CONFIG,
      data: { ...DEFAULT_CONFIRMATION_POPUP_CONFIG.data, ...config },
    });
  }

  openInfoPopup(title: string, message: string): MatDialogRef<GenericPopupComponent> {
    const config: MatDialogConfig<GgPopupContentConfig> = {
      width: '90%',
      maxWidth: '470px',
      data: {
        title,
        hasCloseButton: true,
        textContent: message,
        primaryButtonText: 'Okay',
        closeOnPrimaryButtonClick: true,
      },
    };

    return this.openGenericPopup(config);
  }

  openCustomPopup<T, D>(component: ComponentType<T>, config: MatDialogConfig<D> = {}): MatDialogRef<T> {
    const data: GgPopupContentConfig = { ...DEFAULT_POPUP_CONTENT_CONFIG, ...(config.data || {}) };
    return this.dialog.open(component, { ...config, data });
  }

  assignDataToConfig<T>(config: GgPopupConfig<T>, data: T): GgPopupConfig<T> {
    return { ...config, data: { ...config.data, data } };
  }

  patchContentConfig<T>(config: GgPopupConfig<T>, payload: Partial<GgPopupContentConfig<T>>): GgPopupConfig<T> {
    return { ...config, data: { ...config.data, ...payload } };
  }

  closeAll(): void {
    this.dialog.closeAll();
  }
}
