import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'gg-chevron-left-icon',
  templateUrl: './chevron-left-icon.component.html',
  styleUrls: ['./chevron-left-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChevronLeftIconComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
