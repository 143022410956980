import { InjectionToken } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { GgErrorType } from '@core/enums/form-errors.enums';
import { GgDefaultFormErrorFn, GgFormErrors } from '@core/models/form-errors.models';

export const defaultErrors: GgFormErrors = {
  [GgErrorType.REQUIRED]: () => `Required`,
  [GgErrorType.EMAIL]: () => `Invalid email address`,
  [GgErrorType.MIN]: ({ min }: ValidationErrors) => `At least ${min}`,
  [GgErrorType.MAX]: ({ max }: ValidationErrors) => `No more than ${max}`,
  [GgErrorType.MIN_LENGTH]: ({ requiredLength }: ValidationErrors) => `At least ${requiredLength} characters`,
  [GgErrorType.MAX_LENGTH]: ({ requiredLength }: ValidationErrors) => `No more than ${requiredLength} characters`,
  [GgErrorType.UNSELECTED]: () => `Please choose from the available list`,
  [GgErrorType.PATTERN]: () => `The field does not match a pattern`,
  [GgErrorType.MATCHING]: () => `Doesn't match`,
  [GgErrorType.MAT_DATEPICKER_PARSE]: () => `Invalid date format`,
  [GgErrorType.MAT_DATEPICKER_MIN]: ({ min }) => `Invalid date`,
  [GgErrorType.NON_CORPORATE_DOMAIN]: () => `Please use corporate domain only`,
  [GgErrorType.INVALID_DOMAINS]: () => `Invalid Domain`,
};

export const FORM_ERRORS: InjectionToken<GgFormErrors> = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors,
});

export enum CustomErrorType {
  NAME_PATTERN,
  EMAIL_PATTERN,
  PHONE_PATTERN,
  URL_PATTERN,
  BAR_NUMBER_PATTERN,
  ZIPCODE_PATTERN,
  PASSWORD_PATTERN,
  LETTERS_AND_NUMBERS,
  PASSWORDS_MISMATCH,
  ONLY_LETTERS,
}

export const CUSTOM_ERROR_MESSAGES = {
  [CustomErrorType.NAME_PATTERN]: `Must have at least 2 characters length, doesn't start and end with space and doesn't include special characters`,

  [CustomErrorType.URL_PATTERN]: 'Invalid link format',

  [CustomErrorType.LETTERS_AND_NUMBERS]: 'Only letters and numeric characters',

  [CustomErrorType.BAR_NUMBER_PATTERN]: 'Only digits',

  [CustomErrorType.ONLY_LETTERS]: 'Only letters',

  [CustomErrorType.PASSWORDS_MISMATCH]: `Password entries doesn't match`,

  [CustomErrorType.PASSWORD_PATTERN]:
    'Password should contain at least one: special character, capital letter, lowercase letter, number',

  [CustomErrorType.EMAIL_PATTERN]: (defaultErrors[GgErrorType.EMAIL] as GgDefaultFormErrorFn)(),
};
