import { ApiError } from '@core/models/api-error.modes';
import { Client, ClientOffice, ClientPayload } from '@core/models/client.models';
import { createAction, props } from '@ngrx/store';

export const loadClients = createAction('[Client API] Load Clients');
export const loadClientsSuccess = createAction('[Client API] Load Clients Success', props<{ response: Client[] }>());
export const loadClientsFailure = createAction('[Client API] Load Clients Failure', props<{ error: ApiError }>());

export const pullOne = createAction('[Client API] Pull One', props<{ payload: string }>());
export const pullOneSuccess = createAction('[Client API] Pull One Success', props<{ response: Client }>());
export const pullOneFailure = createAction('[Client API] Pull One Failure', props<{ error: ApiError }>());

export const addClient = createAction('[Client API] Add Clients', props<{ payload: ClientPayload }>());
export const addClientSuccess = createAction('[Client API] Add Clients Success', props<{ response: Client }>());
export const addClientFailure = createAction('[Client API] Add Clients Failure', props<{ error: ApiError }>());

export const patchClient = createAction('[Client API] Patch Clients', props<{ payload: Partial<ClientPayload> }>());
export const patchClientSuccess = createAction('[Client API] Patch Clients Success', props<{ response: Client }>());
export const patchClientFailure = createAction('[Client API] Patch Clients Failure', props<{ error: ApiError }>());

export const deleteClient = createAction('[Client API] Delete Clients', props<{ payload: string }>());
export const deleteClientSuccess = createAction('[Client API] Delete Clients Success', props<{ response: string }>());
export const deleteClientFailure = createAction('[Client API] Delete Clients Failure', props<{ error: ApiError }>());

export const patchClientLocally = createAction(
  '[Client API] Patch Client Locally',
  props<{ payload: Partial<Client> }>()
);

export const addOfficeLocally = createAction(
  '[Client API] Add Office Locally',
  props<{ clientId: string; payload: ClientOffice }>()
);

export const patchOfficeLocally = createAction(
  '[Client API] Patch Office Locally',
  props<{ clientId: string; payload: Partial<ClientOffice> }>()
);

export const deleteOfficeLocally = createAction(
  '[Client API] Delete Office Locally',
  props<{ clientId: string; payload: string }>()
);

export const selectClient = createAction('[Client API] Select Client', props<{ payload: string }>());

export const reset = createAction('[Client API] Reset');
