import { Component, ChangeDetectionStrategy, ContentChild, Inject, Output, EventEmitter, Input } from '@angular/core';
import { PopupContentComponent, PopupFooterComponent, PopupHeaderComponent } from '..';
import { DEFAULT_POPUP_CONTENT_CONFIG } from '@core/constants/dialog.constants';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GgPopupCloseEvent, GgPopupContentConfig } from '@core/models/popup.models';

@UntilDestroy()
@Component({
  selector: 'gg-generic-popup',
  templateUrl: './generic-popup.component.html',
  styleUrls: ['./generic-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericPopupComponent {
  @Input() disabled: boolean = false;
  @Input() isLoading: boolean = false;

  @Output() primaryButtonClick: EventEmitter<Event> = new EventEmitter();
  @Output() secondaryButtonClick: EventEmitter<Event> = new EventEmitter();

  @ContentChild(PopupHeaderComponent) public projectedPopupHeader: PopupHeaderComponent;
  @ContentChild(PopupContentComponent) public projectedPopupContent: PopupContentComponent;
  @ContentChild(PopupFooterComponent) public projectedPopupFooter: PopupFooterComponent;

  constructor(
    private dialogRef: MatDialogRef<GenericPopupComponent, GgPopupCloseEvent>,
    @Inject(MAT_DIALOG_DATA) public config: GgPopupContentConfig = DEFAULT_POPUP_CONTENT_CONFIG
  ) {}

  close(): void {
    this.dialogRef.close({ closeBtnClicked: true });
  }

  handlePrimaryClick(event: Event): void {
    if (this.config.closeOnPrimaryButtonClick) {
      this.dialogRef.close({ primaryBtnClicked: true });
    }

    this.primaryButtonClick.emit(event);
  }

  handleSecondaryClick(event: Event): void {
    if (this.config.closeOnSecondaryButtonClick) {
      this.dialogRef.close({ secondaryBtnClicked: true });
    }

    this.secondaryButtonClick.emit(event);
  }

  onPrimaryButtonClick(handler: (event: Event) => unknown): void {
    this.primaryButtonClick.pipe(untilDestroyed(this)).subscribe(handler);
  }

  onSecondaryButtonClick(handler: (event: Event) => unknown): void {
    this.secondaryButtonClick.pipe(untilDestroyed(this)).subscribe(handler);
  }
}
