import { Type } from '@angular/core';
import { PopupContentComponent } from './popup-content/popup-content.component';
import { PopupFooterComponent } from './popup-footer/popup-footer.component';
import { PopupHeaderComponent } from './popup-header/popup-header.component';

export * from './popup-header/popup-header.component';
export * from './popup-footer/popup-footer.component';
export * from './popup-content/popup-content.component';

export const components: Array<Type<any>> = [PopupHeaderComponent, PopupFooterComponent, PopupContentComponent];
