<div class="generic-popup">
  <ng-container *ngIf="!projectedPopupHeader; else projectedPopupHeaderTpl">
    <gg-popup-header [config]="config" (close)="close()"></gg-popup-header>
  </ng-container>

  <ng-container *ngIf="!projectedPopupContent; else projectedPopupContentTpl">
    <gg-popup-content [config]="config"></gg-popup-content>
  </ng-container>

  <ng-container *ngIf="!projectedPopupFooter; else projectedPopupFooterTpl">
    <gg-popup-footer
      [config]="config"
      [disabled]="disabled"
      [isLoading]="isLoading"
      (primaryButtonClick)="handlePrimaryClick($event)"
      (secondaryButtonClick)="handleSecondaryClick($event)"
    ></gg-popup-footer>
  </ng-container>
</div>

<ng-template #projectedPopupHeaderTpl>
  <ng-content select="gg-popup-header"></ng-content>
</ng-template>

<ng-template #projectedPopupContentTpl>
  <ng-content select="gg-popup-content"></ng-content>
</ng-template>

<ng-template #projectedPopupFooterTpl>
  <ng-content select="gg-popup-footer"></ng-content>
</ng-template>
