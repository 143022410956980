import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { initialState, UserState } from './user.state';

import * as userActions from './user.actions';

export const USER_FEATURE_KEY = 'user';

export const userReducer: ActionReducer<UserState, Action> = createReducer(
  initialState,

  on(userActions.setUser, (state, action) => {
    return { ...state, data: action.payload, isLoaded: true };
  }),

  on(userActions.loadUser, (state) => ({ ...state, isLoaded: true })),

  on(userActions.loadUserSuccess, (state, action) => {
    return { ...state, data: action.response, isLoaded: true, isLoading: false };
  }),

  on(userActions.loadUserFailure, (state) => ({ ...state, isLoaded: false, isLoading: false })),

  on(userActions.patchUserSuccess, (state, action) => {
    return { ...state, data: action.response };
  }),

  on(userActions.reset, () => initialState)
);
