<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/menu/clients</title>
  <g id="icons/menu/clients" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <rect id="Rectangle" stroke="#FFFFFF" stroke-width="2" x="1" y="4" width="16" height="13" rx="1"></rect>
    <path
      d="M14.6672911,17.0247958 C13.8506602,14.6814677 11.621694,13 9,13 C6.37581904,13 4.14511135,14.6846593 3.33038869,17.0314663"
      id="Path"
      stroke="#FFFFFF"
      stroke-width="2"
    ></path>
    <circle id="Oval" stroke="#FFFFFF" stroke-width="2" cx="9" cy="10" r="3"></circle>
    <path
      d="M3,4 L3,2 C3,1.44771525 3.44771525,1 4,1 L14,1 C14.5522847,1 15,1.44771525 15,2 L15,3.95185826 L15,3.95185826"
      id="Path"
      stroke="#FFFFFF"
      stroke-width="2"
    ></path>
  </g>
</svg>
