<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/edit</title>
  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="icons/edit">
      <rect
        id="Rectangle"
        fill="currentColor"
        transform="translate(11.707107, 6.792893) rotate(-315.000000) translate(-11.707107, -6.792893) "
        x="10.7071068"
        y="2.29289322"
        width="2"
        height="9"
      ></rect>
      <rect
        id="Rectangle"
        fill="currentColor"
        transform="translate(15.949747, 2.550253) rotate(-315.000000) translate(-15.949747, -2.550253) "
        x="14.9497475"
        y="2.05025253"
        width="2"
        height="1"
        rx="0.5"
      ></rect>
      <polygon
        id="Rectangle"
        fill="currentColor"
        transform="translate(6.757359, 11.742641) rotate(-315.000000) translate(-6.757359, -11.742641) "
        points="5.75735931 10.2426407 7.75735931 10.2426407 7.75735931 12.2426407 6.75735931 13.2426407 5.75735931 12.2426407"
      ></polygon>
      <path
        d="M15,8.02928708 L15,8.02928708 L15,14 C15,14.5522847 14.5522847,15 14,15 L4,15 C3.44771525,15 3,14.5522847 3,14 L3,4 C3,3.44771525 3.44771525,3 4,3 L11.042988,3 L11.042988,3"
        id="Path"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
      ></path>
    </g>
  </g>
</svg>
