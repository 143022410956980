import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { RootStoreModule } from '@store/root-store.module';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '@environment';
import { UntypedFormBuilder } from '@angular/forms';
import { GgFormBuilder } from '@core/models/forms.models';
import { NgxMaskModule } from 'ngx-mask';
import { APP_EVENTS } from '@core/constants/app-events.constants';
import { VersionRendererComponent } from '@shared/components/version-renderer/version-renderer.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppEventsApiService } from '@core/services/api/app-events-api.service';
import { GgDateAdapter } from '@core/services/helpers/date-adapter.api';
import { CustomScrollbar } from '@core/providers/app.providers';
import { enUS } from 'date-fns/locale';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'P',
  },
  display: {
    dateInput: 'P',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'PP',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    BrowserModule,
    RootStoreModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    VersionRendererComponent,
    NgxStripeModule.forRoot(environment.stripeKey),
    NgxMaskModule.forRoot({}),
  ],
  providers: [
    CustomScrollbar,
    { provide: UntypedFormBuilder, useClass: GgFormBuilder },
    { provide: APP_EVENTS, useClass: AppEventsApiService },
    {
      provide: DateAdapter,
      useClass: GgDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: enUS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
