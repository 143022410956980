import { Injectable } from '@angular/core';
import { AppEvent, AppEventsBase, AppEventType } from '@core/models/app-events.models';
import { filter, map, Observable, Subject } from 'rxjs';

@Injectable()
export class AppEventsApiService implements AppEventsBase {
  private readonly events$: Subject<AppEvent<unknown>> = new Subject();

  emit<T>(type: AppEventType, payload: T): void {
    this.events$.next({ type, payload });
  }

  listen<T>(type: AppEventType): Observable<T> {
    return this.events$
      .asObservable()
      .pipe(filter((event: AppEvent<T>) => event.type === type))
      .pipe(map((event: AppEvent<T>) => event.payload));
  }
}
