import { ApiError } from '@core/models/api-error.modes';
import { Class, ClassPayload, LoadClassesResponse } from '@core/models/class.models';
import { WlcmQueryParams } from '@wlcm/common';
import { createAction, props } from '@ngrx/store';

export const loadClasses = createAction('[Class API] Load Classes', props<{ payload?: Partial<WlcmQueryParams> }>());
export const loadClassesSuccess = createAction(
  '[Class API] Load Classes Success',
  props<{ response: LoadClassesResponse }>()
);
export const loadClassesFailure = createAction('[Class API] Load Classes Failure', props<{ error: ApiError }>());

export const loadMoreClasses = createAction(
  '[Class API] Load More Classes',
  props<{ payload?: Partial<WlcmQueryParams> }>()
);
export const loadMoreClassesSuccess = createAction(
  '[Class API] Load More Classes Success',
  props<{ response: LoadClassesResponse }>()
);
export const loadMoreClassesFailure = createAction(
  '[Class API] Load More Classes Failure',
  props<{ error: ApiError }>()
);

export const createClass = createAction('[Class API] Create Class', props<{ payload?: ClassPayload }>());
export const createClassSuccess = createAction('[Class API] Create Class Success', props<{ response: Class }>());
export const createClassFailure = createAction('[Class API] Create Class Failure', props<{ error: ApiError }>());

export const patchClass = createAction('[Class API] Patch Class', props<{ payload?: ClassPayload }>());
export const patchClassSuccess = createAction('[Class API] Patch Class Success', props<{ response: Class }>());
export const patchClassFailure = createAction('[Class API] Patch Class Failure', props<{ error: ApiError }>());

export const deleteClass = createAction('[Class API] Delete Class', props<{ payload?: string }>());
export const deleteClassSuccess = createAction('[Class API] Delete Class Success', props<{ response: Class }>());
export const deleteClassFailure = createAction('[Class API] Delete Class Failure', props<{ error: ApiError }>());

export const selectOne = createAction('[Class API] Select One', props<{ payload: string }>());

export const deselectOne = createAction('[Class API] Deselect One', props<{ payload: string }>());

export const deselectAll = createAction('[Class API] Deselect All');

export const resetClassesState = createAction('[Class API] Reset Classes State');
