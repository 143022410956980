import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import * as fromAuth from '@store/auth';
import * as fromUser from '@store/user';
import * as fromClasses from '@store/classes';
import * as fromSubscription from '@store/subscription';
import * as fromClients from '@store/clients';
import * as fromOffices from '@store/offices';
import * as fromArticles from '@store/articles';
import * as fromCurrentRecommendation from '@store/current-recommendation';
import * as fromArea from '@store/area';

export type AppState = {
  [fromAuth.AUTH_FEATURE_KEY]: fromAuth.AuthState;
  [fromUser.USER_FEATURE_KEY]: fromUser.UserState;
  [fromClasses.CLASSES_FEATURE_KEY]: fromClasses.ClassesState;
  [fromSubscription.SUBSCRIPTION_FEATURE_KEY]: fromSubscription.SubscriptionState;
  [fromClients.CLIENTS_FEATURE_KEY]: fromClients.ClientsState;
  [fromOffices.OFFICES_FEATURE_KEY]: fromOffices.OfficesState;
  [fromArticles.ARTICLES_FEATURE_KEY]: fromArticles.ArticlesState;
  [fromCurrentRecommendation.CURRENT_RECOMMENDATION_FEATURE_KEY]: fromCurrentRecommendation.CurrentRecommendationState;
  [fromArea.AREAS_FEATURE_KEY]: fromArea.AreaState;
};

export const reducers: ActionReducerMap<AppState> = {
  [fromAuth.AUTH_FEATURE_KEY]: fromAuth.authReducer,
  [fromUser.USER_FEATURE_KEY]: fromUser.userReducer,
  [fromClasses.CLASSES_FEATURE_KEY]: fromClasses.classesReducer,
  [fromSubscription.SUBSCRIPTION_FEATURE_KEY]: fromSubscription.subscriptionReducer,
  [fromClients.CLIENTS_FEATURE_KEY]: fromClients.clientsReducer,
  [fromOffices.OFFICES_FEATURE_KEY]: fromOffices.officesReducer,
  [fromArticles.ARTICLES_FEATURE_KEY]: fromArticles.articlesReducer,
  [fromCurrentRecommendation.CURRENT_RECOMMENDATION_FEATURE_KEY]:
    fromCurrentRecommendation.currentRecommendationReducer,
  [fromArea.AREAS_FEATURE_KEY]: fromArea.areaReducer,
};

const authKeyConfiguration = { [fromAuth.AUTH_FEATURE_KEY]: [...Object.keys(fromAuth.initialState)] };

export function localStorageSyncReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return localStorageSync({ keys: [authKeyConfiguration], rehydrate: true })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];
