import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserType } from '@core/models/user.models';
import { DefaultRouteApiService } from '@core/services/api/default-route-api.service';
import { UserFacade } from '@store/user/user.facade';
import { combineLatest, map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DefaultRouteGuard  {
  constructor(
    private router: Router,
    private userFacade: UserFacade,
    private defaultRouteApi: DefaultRouteApiService
  ) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (state.url.split('/').filter(Boolean).length > 0) {
      return true;
    }

    return combineLatest([this.userFacade.user$, this.defaultRouteApi.determineDefaultRoute()]).pipe(
      map(([user, route]) => {
        if ((user.is_verified && user.subscription) || user.type !== UserType.Client) {
          this.router.navigateByUrl(`/${route}`);
          return false;
        }

        return true;
      })
    );
  }
}
