<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/menu/Recommendations</title>
  <g id="icons/menu/Recommendations" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path
      d="M7.90693177,11.5489289 L4.24419767,13.4745421 C4.14642875,13.5259423 4.02550332,13.488353 3.97410316,13.3905841 C3.95363534,13.351652 3.94657241,13.3070584 3.95400778,13.2637068 L4.65352774,9.18519247 C4.66465533,9.12031366 4.64314563,9.05411362 4.59600842,9.00816619 L1.63279428,6.11974628 C1.55369756,6.04264603 1.5520791,5.91602338 1.62917935,5.83692666 C1.65988107,5.80542993 1.70010955,5.7849325 1.74363696,5.7786076 L5.83869818,5.18356037 C5.90384021,5.17409468 5.96015333,5.1331808 5.98928573,5.07415211 L7.82065278,1.36339716 C7.86953724,1.26434642 7.98946241,1.22367862 8.08851314,1.27256308 C8.12795567,1.29202913 8.15988117,1.32395463 8.17934722,1.36339716 L10.0107143,5.07415211 C10.0398467,5.1331808 10.0961598,5.17409468 10.1613018,5.18356037 L14.256363,5.7786076 C14.365672,5.79449112 14.4414083,5.89597969 14.4255248,6.00528867 C14.4191999,6.04881607 14.3987024,6.08904456 14.3672057,6.11974628 L11.4039916,9.00816619 C11.3568544,9.05411362 11.3353447,9.12031366 11.3464723,9.18519247 L12.0459922,13.2637068 C12.0646644,13.3725741 11.9915469,13.4759653 11.8826796,13.4946375 C11.839328,13.5020729 11.7947344,13.49501 11.7558023,13.4745421 L8.09306823,11.5489289 C8.03480342,11.5182972 7.96519658,11.5182972 7.90693177,11.5489289 Z"
      id="Star"
      stroke="#FFFFFF"
      stroke-width="2"
    ></path>
    <rect id="Rectangle" fill="#FFFFFF" x="0" y="16" width="18" height="2" rx="1"></rect>
    <rect id="Rectangle" fill="#FFFFFF" x="14" y="12" width="4" height="2" rx="1"></rect>
  </g>
</svg>
