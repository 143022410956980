<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/menu/Engagement</title>
  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="icons/menu/Engagement" stroke="currentColor" stroke-width="2">
      <path
        d="M8,10 C8.00976813,4.87789433 8.01242111,1.89819611 8.00795894,1.06090534 C4.1023676,1.26831244 1,4.91764017 1,9 C1,13.418278 4.581722,17 9,17 C13.0580086,17 16.4833024,13.9159423 17,10 C15.5410873,9.97908532 12.5410873,9.97908532 8,10 Z"
        id="Path"
      ></path>
      <path d="M17,7 C17,3.6862915 14.3137085,1 11,1 L11,7 L17,7 Z" id="Path"></path>
    </g>
  </g>
</svg>
