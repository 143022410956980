import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from './user.state';
import { User } from '@core/models/user.models';
import { StoreActionResponseApiService } from '@core/services/api/store-action-response-api.service';
import { combineLatest, map, Observable, take } from 'rxjs';

import * as userActions from './user.actions';
import * as userSelectors from './user.selectors';

@Injectable({ providedIn: 'root' })
export class UserFacade {
  readonly user$: Observable<User> = this.store.select(userSelectors.selectUser);
  readonly isLoaded$: Observable<boolean> = this.store.select(userSelectors.selectIsLoaded);
  readonly isLoading$: Observable<boolean> = this.store.select(userSelectors.selectIsLoaded);

  readonly isStatePristine$: Observable<boolean> = combineLatest([
    this.isLoaded$.pipe(take(1)),
    this.isLoading$.pipe(take(1)),
  ]).pipe(map(([isLoaded, isLoading]) => !isLoaded && !isLoading));

  constructor(
    private readonly store: Store<UserState>,
    private readonly storeActionResponseApi: StoreActionResponseApiService
  ) {}

  setUser(user: User): void {
    this.store.dispatch(userActions.setUser({ payload: user }));
  }

  loadUser(): Observable<User> {
    this.store.dispatch(userActions.loadUser());
    return this.storeActionResponseApi.actionResponse<User>(userActions.loadUserSuccess, userActions.loadUserFailure);
  }

  patch(user: Partial<User>): Observable<User> {
    this.store.dispatch(userActions.patchUser({ payload: user }));
    return this.storeActionResponseApi.actionResponse<User>(userActions.patchUserSuccess, userActions.patchUserFailure);
  }
}
