import { HttpClient, HttpParams } from '@angular/common/http';
import { Class, ClassPayload, LoadClassesResponse, ShareClass } from '@core/models/class.models';
import { WlcmQueryParams } from '@wlcm/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GgHttpUtils } from '@core/utils/http.utils';

const FEATURE: string = 'class';

@Injectable({ providedIn: 'root' })
export class ClassApiService {
  constructor(private readonly http: HttpClient) {}

  get(searchParams: WlcmQueryParams): Observable<LoadClassesResponse> {
    const queryParams: HttpParams = new HttpParams().appendAll(searchParams as any);

    return this.http.get<LoadClassesResponse>(FEATURE, { params: queryParams });
  }

  getOne(id: string): Observable<Class> {
    return this.http.get<Class>(`${FEATURE}/${id}`);
  }

  create(payload: ClassPayload): Observable<Class> {
    const formData: FormData = GgHttpUtils.transformToFormData(payload);

    return this.http.post<Class>(FEATURE, formData);
  }

  patch(payload: ClassPayload): Observable<Class> {
    const formData: FormData = GgHttpUtils.transformToFormData(payload);

    return this.http.patch<Class>(FEATURE, formData);
  }

  delete(id: string): Observable<Class> {
    return this.http.delete<Class>(`${FEATURE}/${id}`);
  }

  share(payload: ShareClass): Observable<unknown> {
    return this.http.post<unknown>(`${FEATURE}/share`, payload);
  }
}
