<svg
  width="18px"
  height="16px"
  viewBox="0 0 18 16"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>icons/menu/logout</title>
  <g id="1.0-Broker-or-Carrier" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="1.0.0-Dashboard-Calendar-View" transform="translate(-16.000000, -1068.000000)">
      <g id="Group" transform="translate(16.000000, 1067.000000)">
        <circle id="Oval" stroke="#FFFFFF" stroke-width="2" cx="10" cy="9" r="7"></circle>
        <path
          d="M6.99762249,2 C7.54990724,2 7.99762249,2.44771525 7.99762249,3 L7.997,12.585 L9.29361472,11.2921725 C9.65446612,10.9320563 10.2217252,10.9049053 10.6137051,11.2103301 L10.7078275,11.2936147 C11.0679437,11.6544661 11.0950947,12.2217252 10.7896699,12.6137051 L10.7063853,12.7078275 L7.70026007,15.7078275 C7.33940813,16.0679442 6.77214808,16.0950947 6.38016819,15.7896688 L6.28604577,15.7063838 L3.29217099,12.7063838 C2.90204598,12.3154606 2.90269302,11.682296 3.29361619,11.292171 C3.65446835,10.9320556 4.22172744,10.9049057 4.61370674,11.2103314 L4.70782901,11.2936162 L5.997,12.586 L5.99762249,3 C5.99762249,2.44771525 6.44533774,2 6.99762249,2 Z"
          id="Combined-Shape"
          fill="#FFFFFF"
          transform="translate(7.000000, 9.000000) rotate(-90.000000) translate(-7.000000, -9.000000) "
        ></path>
      </g>
    </g>
  </g>
</svg>
