import { Injectable } from '@angular/core';
import { UserFacade } from '@store/user/user.facade';
import { User, UserType } from '@core/models/user.models';
import { ACCOUNTS_ROUTE_CONFIG, CLIENT_ROUTE_CONFIG, OFFICE_ROUTE_CONFIG } from '@core/constants/routes.constants';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DefaultRouteApiService {
  constructor(private userFacade: UserFacade) {}

  determineDefaultRoute(): Observable<string> {
    return this.userFacade.user$.pipe(
      map((user: User) => {
        if (user.type === UserType.Admin) {
          return ACCOUNTS_ROUTE_CONFIG.root;
        } else if (user.type === UserType.Client) {
          return OFFICE_ROUTE_CONFIG.root;
        }

        return CLIENT_ROUTE_CONFIG.root;
      })
    );
  }
}
