import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, exhaustMap, map } from 'rxjs';
import { UserApiService } from '@core/services/api/user-api.service';

import * as userActions from './user.actions';
import * as authActions from '../auth/auth.actions';

import { BaseEffect } from '@store/base/base.models';

@Injectable()
export class UserEffects extends BaseEffect {
  constructor(private readonly userApi: UserApiService, private readonly actions$: Actions) {
    super();
  }

  loadUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loadUser),
      exhaustMap(() =>
        this.userApi.get().pipe(this.handleResponse(userActions.loadUserSuccess, userActions.loadUserFailure))
      )
    )
  );

  patchUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.patchUser),
      exhaustMap((action) =>
        this.userApi
          .update(action.payload)
          .pipe(this.handleResponse(userActions.patchUserSuccess, userActions.patchUserFailure))
      )
    )
  );

  reset$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logOutSuccess),
      map(() => userActions.reset())
    )
  );
}
