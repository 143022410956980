import { Injectable } from '@angular/core';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { format, startOfWeek, setDay } from 'date-fns';

@Injectable()
export class GgDateAdapter extends DateFnsAdapter {
  override getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    let formatString: string = style === 'long' ? 'EEEE' : 'EEE';

    const startDate = startOfWeek(new Date(), { weekStartsOn: 0 });

    return Array.from({ length: 7 }, (_, i) => format(setDay(startDate, i), formatString));
  }
}
