import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'gg-instructors-icon',
  templateUrl: './instructors-icon.component.html',
  styleUrls: ['./instructors-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstructorsIconComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
